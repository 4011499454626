.our-prices {
    padding-top: 30px;
    padding-bottom: 60px;
    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px 15px;
    }
    &__list-opt {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px 15px;
    }
    &__item {
        font-size: .9rem;
        display: flex;
        justify-content: space-between;
        background: $light_gray;
        padding: 15px 20px;
        border-radius: 2px;
        box-shadow: 3px 4px 6px $silver;
        transition: background .2s ease-in-out;
    }
    &__item:hover {
        background: $light-blue-hover;
    }
    &__item-inform--title {
        width: 50%;
    }
    &__item-inform--time {
        color: $gray;
    }
    &__item-inform--price {
        font-style: italic;
    }
    &__btn-container {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__opt-product__title {
        font-size: 0.9rem;
        font-weight: 700;
        color: $black;
        margin-bottom: 14px;
    }
    &__actual-price {
        font-size: .8rem;
        font-weight: 500;
        padding: 4px 0;
    }
    &__mb {
        margin-bottom: 6px;
    }
    &__product-percent-discount {
        background-color: red;
        color: white;
        padding: 4px 6px;
    }
    &__price-btn-wrapper {
        padding: 10px 0;
        margin: 5px 0;
    }
    @media(max-width:820px) {
        &__list {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
        &__item {
            font-size: .8rem;
        }
        &__item-inform--title {
            width: 40%;
        }
    }
    @media(max-width:580px) {
        &__list {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }
    }
}

.product-opt {
    padding: 40px 0;
    &__item-wrap:not(:last-child) {
        margin-bottom: 30px;
    }
}

.product__item--hidden-akb--opt:nth-child(n+5) {
    display: none;
}

.product__item--hidden-lcd--opt:nth-child(n+5) {
    display: none;
}

.opt-btn {
    display: block;
    text-align: center;
}

.inline-rub {
    display: inline;
}

.master-price--old {
    text-decoration: line-through;
    margin-bottom: 6px;
    font-weight: 400;
    color: $gray;
}

.ui-tabs-nav {
    display: flex;
    justify-content: space-between;
    gap: 0 30px;
    margin-bottom: 30px;
}

.ui-tabs-tab {
    border: none;
    width: 100%;
    font-size: .9rem;
}

.ui-tabs-tab a {
    display: inline-block;
    border-radius: $radius;
    padding: 10px;
    width: 100%;
    background-color: #e8e8e8;
    color: $blue;
    text-align: center;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.ui-tabs-active a {
    background-color: $blue;
    color: #fff;
}

@media (max-width:864px) {
    .ui-tabs-tab {
        border: none;
        font-size: .7rem;
    }
    .ui-tabs-nav {
        justify-content: center;
    }
}

.opt-akb {
    padding: 10px;
    background-color: rgb(204, 255, 214);
    border-radius: 10px;
}
