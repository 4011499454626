.swiper {
  width: 100%;
  height: 100%;
}
.product-slide {
height: 100%;
}


.product-slider{
  padding-top: 30px;
  padding-bottom: 40px;

  &__wrap{
    height: 510px;
  }
}

.product-slider-btnstyle-next,
.product-slider-btnstyle-prev{
  padding: 10px;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  background: #efefef96;
  box-shadow: 0 0 14px 0px #2f7e9d8c;
}

.product-slider-btnstyle-prev{
left: 0.6rem !important;
}
.product-slider-btnstyle-next{
  right: 0.6rem !important;

}
