$white:#ffffff;
$black:#000000;
$gray:#8D8D8D;
$light_gray:#f1f1f1;
$silver:#D3D3D3;
$blue: #0a74c0;
$iph-bg-blue:#aecbd9;
$green: #5AA34A;
$green-light: #76BC21;
$green-iphone: #A1D4C0;
$greenBG:#E2F4DE;
$dark-blue: #062D3E;
$red: rgb(255, 41, 105);
$blue-hover: #1580cd;
$often-blue: #0874c07a;
$light-blue: #e1f6ff;
$orange:#FE7200;
$light-blue-hover: #b1e0ff;
$dark: #02010e;
$cost-now-bg: #AE15C7;
$cost-now-bg-light: #c54ad8;;
$work-background: #0A8AC0;
$question-form-background: #ebf6ff;
$gradient:linear-gradient(90deg, rgba(2, 123, 187, 1) 0%, rgba(36, 214, 203, 1) 35%, rgba(0, 118, 186, 1) 100%);
$br-5: 5px;
$br-10: 10px;
$sec-bottom: 30px;
$radius: 6px;
$btn-radius: 4px;
$section-banner: #76ccf7;
// fonts
$firstFont: 'Nunito', sans-serif;
$secondFont: 'Open Sans', sans-serif;
//
// :root {
//     --black: #000000;
//     --gray: #8D8D8D;
//     --silver: #D3D3D3;
//     --blue: #0A8AC0;
//     --iph-bg-blue: #aecbd9;
//     --green: #5AA34A;
//     --greenBG: #E2F4DE;
//     --dark-blue: #062D3E;
//     --red: rgb(255, 41, 105);
//     --blue-hover: #53c0ee;
//     --light-blue: #e1f6ff;
//     --light-blue-hover: #b1e0ff;
//     --dark: #02010e;
//     --work-background: #0A8AC0;
//     --question-form-background: #ebf6ff;
//     --gradient: linear-gradient(90deg, rgba(2, 123, 187, 1) 0%, rgba(36, 214, 203, 1) 35%, rgba(0, 118, 186, 1) 100%);
//     --br-5: 5px;
//     --br-10: 10px;
//     --sec-bottom: 90px;
//     --radius: 6px;
//     --btn-radius: 4px;
// }
