.opt-hero {
    color: #fff;
    background: black;
    min-height: 85vh;
    margin-bottom: 30px;
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10%;
    }
    &__content {
        max-width: 50%;
    }
    &__title {
        color: #fff;
        line-height: 3rem;
    }
    &__img-container {
        width: 40%;
    }
    &__desc {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 50px;
        line-height: 2rem;
    }
    &__reg-link {
        display: inline-block;
        margin-bottom: 30px;
        border-bottom: 1px solid transparent;
    }
    &__autorize-link {
        display: block;
        color: $blue;
    }
}

.master-price {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
}

.opt-become {
    padding: 25px 0;
    margin-bottom: 30px;
    &__list {
        display: grid;
        gap: 0 15px;
        grid-template-columns: repeat(4, 1fr);
    }
    &__item {
        padding-top: 80px;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 30%;
    }
    &__item-1 {
        background-image: url('../img/opt-step-1.svg');
    }
    &__item-2 {
        background-image: url('../img/opt-step-2.svg');
    }
    &__item-3 {
        background-image: url('../img/opt-step-3.svg');
    }
    &__item-4 {
        background-image: url('../img/opt-step-4.svg');
    }
    &__item-desc {
        font-size: .9rem;
    }
    @media (max-width:982px) {
        &__list {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }
        &__item {
            padding-top: 70px;
            background-size: 70px;
            margin-bottom: 30px;
        }
    }
    @media (max-width:568px) {
        &__list {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }
        &__item-desc {
            padding-right: 30px;
        }
    }
}

.header__bottom {
    margin-bottom: 0;
}

.sec {
    height: 300vh;
    display: flex;
}

.sticky {
    position: sticky;
    top: 50px;
    align-self: flex-start;
}
