// @import url("https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Rubik+Wet+Paint&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300&family=Open+Sans:wght@300;400;500;600;700&family=Rubik+Wet+Paint&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Nunito", sans-serif;
}

h1,
h2,
h3 {
  margin: 0;
}
// замороженный шрифт
// font-family: 'Rubik Wet Paint', cursive;

body {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: rgba(243, 243,243,0.6);
  overflow-x: hidden;
}

button {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $gray;
}

button {
  cursor: pointer;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.hidden {
  visibility: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.btn {
  color: $blue;
  border: 1px solid $blue;
  border-radius: $btn-radius;
  padding: 8px 18px;
  background-color: transparent;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border 0.1s ease-in-out;
}

.btn--add {
  background-color: pink;
}

.btn:hover {
  color: #000000;
  border: 1px solid $blue-hover;
  background-color: $blue-hover;
}

.btn:active {
  color: #ffffff;
  border: 1px solid #000000;
  background-color: rgb(0, 0, 0);
}

.btn--blue {
  color: $white;
  background-color: $blue;
  border-color: transparent !important;
}

.btn--blue:hover {
  color: $white;
  background-color: $blue-hover;
}

.btn--blue:active {
  background-color: $black;
}

.btn--white {
  color: $white;
  border: 1px solid #fff;
}

.btn--white:hover {
  color: $white;
  border: 1px solid rgb(0, 0, 0);
  background-color: black;
}

.btn--white:active {
  color: $black;
  border: 1px solid transparent;
  background-color: #fff;
}

.lineOne {
  display: inline-block;
  background: $gradient;
  min-width: 115px;
  min-height: 4px;
  margin-bottom: 30px;
}

.title-h1 {
  color: $dark;
  font-weight: 500;
  font-size: 1.8rem;
  margin-bottom: 30px;
}

.title-h2 {
  color: $dark;
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 22px;
}

.title-h3 {
  color: $dark;
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 30px;
}

.title-h4 {
  color: $dark;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

// yandex btn
.link-yandex {
  display: inline-block;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  padding-left: 20px;
  border: 1px solid #000000;
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 45px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-image: url(../img/ya-btn.svg);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: center;
  background-size: 28px;
}

.blue-link {
  color: $blue;
}

// social-icon
.social-icon {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 65px;
  height: 65px;
  background-size: 40%;
  border-radius: 12px;
  box-shadow: inset 0px 0px 25px rgba(220, 220, 220, 0.14);
  backdrop-filter: blur(100px);
  transition: box-shadow 0.1s ease-in-out;
}

.social-icon:hover {
  box-shadow: inset 0px 0px 7px #0a8ac0;
}

.icon-insta {
  background-image: url(/img/icon-inst.svg);
}

.icon-tg {
  background-image: url(/img/icon-tg.svg);
}

.icon-whats {
  background-image: url(/img/icon-whts.svg);
}

.icon-vk {
  background-image: url(/img/vk.svg);
}

.mobile-visible {
  display: none !important;
}

@media (max-width: 650px) {
  .mobile-visible {
    display: inline-block;
  }
}
