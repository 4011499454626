.fixed-container__background {
    background-color: $light-blue;
    box-shadow: 0 0 15px rgba(168, 168, 168, 0.1);
}

.fixed-container {
    position: relative;
    display: flex;
    min-height: 70vh;
    padding: 20px 0px;
    border-radius: $radius;
}

.fixed-container__background:not(:last-child) {
    margin-bottom: 15px;
}

.info-title {
    font-size: 26px;
    margin-bottom: 30px;
}

.left-container-fixed {
    position: relative;
    width: 50vw;
}

.left-container-info--fixed {
    position: sticky;
    top: 20vh;
}

.right-container-fixed {
    width: 50vw;
}

.left-container__fixed-title {
    font-size: 34px;
    font-weight: 800;
    margin-bottom: 35px;
}

.left-container__fixed-desc {
    font-size: 26px;
    font-weight: 600;
    padding-right: 15px;
    max-width: 350px;
}

.right-container-fixed-desc {
    font-size: 16px;
    line-height: 24px;
}

.fixed-container-line {
    margin-bottom: 10px;
}

@media (max-width: 992px) {
    .fixed-container {
        flex-direction: column;
        height: auto;
    }
    .right-container-fixed {
        width: 100vw;
    }
}

@media (max-width: 670px) {
    .left-container-fixed {
        position: relative;
        width: 90vw;
    }
    .left-container__fixed-desc {
        font-size: 22px;
    }
    .right-container-info {
        width: 90vw;
    }
}
