.types {
  position: relative;
  padding: 15px 0;
  &__card-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    background: transparent;
    padding-bottom: 15px;
  }
  &__text-wrapper {
    margin-top: 5px;
  }
  &__item-title {
    font-size: 1.3rem;
    font-weight: 800;
    max-width: 180px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__item-desc {
    margin-bottom: 30px;
    padding-right: 15px;
    font-size: 0.85rem;
  }
  &__item-content-price{
    font-family: $firstFont, serif;

  }
  &__item-price {
    color: $blue;
    font-size: 1.3rem;
    font-weight: 600;
    display: block;
    margin-bottom: 25px;
  }
  &__item-content-container img {
    width: 100%;
  }
  &-trigger {
    z-index: 1001;
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    background-color: #00000093;
    color: rgb(0, 0, 0);
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
  }
  &-trigger-active {
    display: flex;
  }
  &-trigger__container {
    z-index: 1002;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 30px 50px;
    max-width: 600px;
    background-color: #fff;
    padding: 30px 50px;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
  }
  &-trigger__container-active {
    transform: translateX(0%);
  }
  &-trigger__close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 0.5px;
  }
}

.form__alert-text{
  font-size: .8rem;
  text-align: center;
}

.form__alert {
  margin-top: 15px;
  padding: 4px;
  border-radius: $radius;
  transition: opacity .2s ease-in-out;
}

.form__alert--danger {
  background-color: #ff9b9b;
}

.form__alert--success {
  background-color: #b8ffcc;
}
// .popup-left-trigger {
//     margin-left: 15px;
// }
.btn-types {
  display: inline-block;
  padding: 8px 10px;
  max-width: 150px;
  margin-left: 10px;
}

@media (max-width: 650px) {
  .types__card-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
