.contacts-info {
    margin-bottom: 30px;

    &__desc{
        font-size: .9rem;
        line-height: 1.2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
    &__img{
        width: 100%;
    }
    &__img--link{
        width: 100%;
    }

}


@media (max-width: 792px){
    .contacts-info__desc{
        grid-template-columns: 1fr;
    }
}