@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300&family=Open+Sans:wght@300;400;500;600;700&family=Rubik+Wet+Paint&display=swap");
* {
  box-sizing: border-box; }

html {
  font-size: 16px; }

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Nunito", sans-serif; }

h1,
h2,
h3 {
  margin: 0; }

body {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: rgba(243, 243, 243, 0.6);
  overflow-x: hidden; }

button {
  cursor: pointer;
  margin: 0;
  padding: 0; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

a {
  text-decoration: none;
  cursor: pointer;
  color: #8D8D8D; }

button {
  cursor: pointer; }

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px; }

.hidden {
  visibility: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.btn {
  color: #0a74c0;
  border: 1px solid #0a74c0;
  border-radius: 4px;
  padding: 8px 18px;
  background-color: transparent;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border 0.1s ease-in-out; }

.btn--add {
  background-color: pink; }

.btn:hover {
  color: #000000;
  border: 1px solid #1580cd;
  background-color: #1580cd; }

.btn:active {
  color: #ffffff;
  border: 1px solid #000000;
  background-color: black; }

.btn--blue {
  color: #ffffff;
  background-color: #0a74c0;
  border-color: transparent !important; }

.btn--blue:hover {
  color: #ffffff;
  background-color: #1580cd; }

.btn--blue:active {
  background-color: #000000; }

.btn--white {
  color: #ffffff;
  border: 1px solid #fff; }

.btn--white:hover {
  color: #ffffff;
  border: 1px solid black;
  background-color: black; }

.btn--white:active {
  color: #000000;
  border: 1px solid transparent;
  background-color: #fff; }

.lineOne {
  display: inline-block;
  background: linear-gradient(90deg, #027bbb 0%, #24d6cb 35%, #0076ba 100%);
  min-width: 115px;
  min-height: 4px;
  margin-bottom: 30px; }

.title-h1 {
  color: #02010e;
  font-weight: 500;
  font-size: 1.8rem;
  margin-bottom: 30px; }

.title-h2 {
  color: #02010e;
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 22px; }

.title-h3 {
  color: #02010e;
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 30px; }

.title-h4 {
  color: #02010e;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 30px; }

.link-yandex {
  display: inline-block;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  padding-left: 20px;
  border: 1px solid #000000;
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 45px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-image: url(../img/ya-btn.svg);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: center;
  background-size: 28px; }

.blue-link {
  color: #0a74c0; }

.social-icon {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 65px;
  height: 65px;
  background-size: 40%;
  border-radius: 12px;
  box-shadow: inset 0px 0px 25px rgba(220, 220, 220, 0.14);
  backdrop-filter: blur(100px);
  transition: box-shadow 0.1s ease-in-out; }

.social-icon:hover {
  box-shadow: inset 0px 0px 7px #0a8ac0; }

.icon-insta {
  background-image: url(/img/icon-inst.svg); }

.icon-tg {
  background-image: url(/img/icon-tg.svg); }

.icon-whats {
  background-image: url(/img/icon-whts.svg); }

.icon-vk {
  background-image: url(/img/vk.svg); }

.mobile-visible {
  display: none !important; }

@media (max-width: 650px) {
  .mobile-visible {
    display: inline-block; } }

.header {
  background-color: #ffffff; }
  .header__tooltip-link {
    padding: 3px 10px; }
  .header__top-right-content--title {
    font-family: "Nunito", sans-serif;
    position: relative;
    padding-right: 10px;
    font-size: .9rem;
    padding-left: 16px; }
  .header__top-right-content--title::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: red;
    left: 0;
    top: 3px;
    border-radius: 100%;
    animation: ligthouse 1s infinite alternate; }

@keyframes ligthouse {
  0% {
    box-shadow: 0 0 0 red; }
  100% {
    box-shadow: 0 0 8px red; } }

.header__top {
  background-color: #f1f1f1; }
  .header__top-right-content {
    display: flex;
    align-items: center; }
  .header__top-tooltip-wrap {
    margin-right: 30px;
    position: relative; }
  .header__top-tooltip-info-btn {
    background: white;
    color: red;
    padding: 3px;
    font-size: 12px;
    font-weight: 600;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    border: 1px solid red;
    transition: color .3s ease-in-out, background .3s ease-in-out; }
  .header__top-tooltip-info-btn--active {
    background: red;
    color: white; }
  .header__top-tooltip-info-container {
    visibility: hidden;
    position: absolute;
    margin-top: 18px;
    background: #d9eefc;
    box-shadow: 2px 2px 12px #dadada;
    border-radius: 4px;
    color: black;
    min-width: 300px;
    padding: 10px 25px;
    right: 0;
    z-index: 500; }
  .header__top-tooltip-info-container--active {
    visibility: visible !important; }
  .header__top-tooltip-info-title {
    font-size: 16px;
    font-weight: 600; }
  .header__top-tooltip-info-desc {
    font-size: 12px;
    padding-right: 5px; }

@keyframes headertooltiparr {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(15px); } }
  .header__top-tooltip-arrow {
    animation: headertooltiparr 1s alternate ease-in-out infinite;
    position: absolute;
    right: -50px;
    top: 50px;
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 8px #5c5c5c;
    border-radius: 100%;
    background: url(../img/tooltip-arrow.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }

.header__top-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0; }

.header__top-nav-list {
  display: flex; }

.header__top-nav-item:not(:last-child) {
  padding-right: 10px;
  border-right: 1px solid #e4e4e4; }

.header__top-nav-item:not(:first-child) {
  padding-left: 10px; }

.header__top-nav-link {
  font-size: 12px;
  border-bottom: 1px solid transparent;
  transition: color .2s ease-in-out, border-bottom .2s ease-in-out; }

.header__top-nav-link:hover {
  color: #0a74c0; }

.header__top-nav-link:active {
  color: #e1f6ff; }

.header__top-mail-title {
  color: #8D8D8D;
  font-size: 14px; }

.header__top-mail-text {
  font-size: 14px;
  transition: color .2s ease-in-out; }

.header__top-mail-text:hover {
  color: #0a74c0;
  border-bottom: 1px solid #0a74c0; }

.header__top-mail-text:active {
  color: #e1f6ff; }

.header__top-city-button {
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #0a74c0;
  border-radius: 5px;
  padding: 4px 15px; }

.header__middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0; }

.header__middle-logo {
  width: 200px; }

.header__middle-list {
  display: flex; }

.header__middle-item:not(:last-child) {
  margin-right: 25px; }

.header__middle-link {
  color: #02010e;
  font-family: "Nunito", sans-serif;
  font-size: .9rem;
  padding-bottom: 3px;
  transition: color .2s ease-in-out; }

.header__middle-link:hover {
  color: #0a74c0; }

.header__middle-callback-block {
  display: flex;
  flex-direction: column; }

.header__middle-callback-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.header__middle-number-phone {
  font-family: "Nunito", sans-serif;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px; }

.header__bottom {
  display: flex;
  justify-content: center;
  background: #0a74c0;
  color: #fff;
  font-size: 12px;
  margin-bottom: 20px; }

.header__bottom-desc a {
  color: #fff;
  border-bottom: 1px solid transparent;
  transition: border-bottom .2s ease-in-out;
  padding-top: 4px;
  padding-bottom: 4px; }

.header__bottom-desc a:hover {
  border-bottom: 1px solid #fff; }

.arrow-right {
  padding-right: 12px;
  background-image: url(/img/arrow.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 8px; }

.burger-toggle-btn {
  display: none; }

.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 20px;
  background: #dfd9ea;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer; }

.switch::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 2px;
  width: 22px;
  height: 22px;
  background: #fafafa;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }

.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1); }

input:checked + .switch {
  background: #72da6791; }

input:checked + .switch::before {
  left: 27px;
  background: #fff; }

input:checked + .switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2); }

.header-top__switch {
  position: relative; }

.header-switch-triangle {
  display: none;
  position: absolute;
  top: 8px;
  left: 6px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom: 20px solid #cce3f3; }

.header-switch-triangle--active {
  display: block; }

@media (max-width: 992px) {
  .header__middle-number-phone {
    font-size: 14px;
    font-weight: 600; }
  .btn {
    font-size: 14px; }
  .header__middle-link {
    color: #02010e;
    padding-bottom: 3px;
    font-size: .8rem; }
  .header__middle-item:not(:last-child) {
    margin-right: 12px; }
  .header__middle-logo {
    width: 180px; } }

@media (max-width: 860px) {
  .header__middle-list {
    transform: translateX(-100%);
    transition: transform .7s ease; }
  .header__middle-list {
    position: absolute;
    left: 0;
    top: 0;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 99;
    padding: 50px 50px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(102, 102, 102, 0.357);
    min-height: 80vh;
    min-width: 50vw; }
  .header__middle-item {
    margin-bottom: 30px; }
  .header__middle-link {
    font-weight: 600;
    outline: none; }
  .header__middle-link:active {
    border: none;
    outline: none; }
  .nav-open {
    transform: translateX(0) !important; }
  .burger-toggle-btn {
    display: none;
    padding: 12px;
    border: none;
    border-radius: 100%;
    background-color: #0a74c0;
    transition: all 0.2s cubic-bezier(0.42, 0, 0.08, 1.44); }
  .burger-toggle-btn {
    display: block !important; }
  .burger-toggle-icon {
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.64341 6.99901L13.6552 1.99813C13.8747 1.77862 13.998 1.48091 13.998 1.17048C13.998 0.860046 13.8747 0.562331 13.6552 0.342824C13.4358 0.123318 13.1381 0 12.8277 0C12.5173 0 12.2196 0.123318 12.0002 0.342824L7 5.35536L1.99983 0.342824C1.78036 0.123318 1.48268 -2.31288e-09 1.1723 0C0.861913 2.31288e-09 0.56424 0.123318 0.344765 0.342824C0.125289 0.562331 0.00198911 0.860046 0.00198911 1.17048C0.00198911 1.48091 0.125289 1.77862 0.344765 1.99813L5.35659 6.99901L0.344765 11.9999C0.235521 12.1083 0.148811 12.2372 0.0896384 12.3792C0.0304655 12.5213 0 12.6736 0 12.8275C0 12.9814 0.0304655 13.1338 0.0896384 13.2758C0.148811 13.4179 0.235521 13.5468 0.344765 13.6552C0.453117 13.7644 0.582027 13.8512 0.724059 13.9103C0.866091 13.9695 1.01843 14 1.1723 14C1.32616 14 1.47851 13.9695 1.62054 13.9103C1.76257 13.8512 1.89148 13.7644 1.99983 13.6552L7 8.64265L12.0002 13.6552C12.1085 13.7644 12.2374 13.8512 12.3795 13.9103C12.5215 13.9695 12.6738 14 12.8277 14C12.9816 14 13.1339 13.9695 13.2759 13.9103C13.418 13.8512 13.5469 13.7644 13.6552 13.6552C13.7645 13.5468 13.8512 13.4179 13.9104 13.2758C13.9695 13.1338 14 12.9814 14 12.8275C14 12.6736 13.9695 12.5213 13.9104 12.3792C13.8512 12.2372 13.7645 12.1083 13.6552 11.9999L8.64341 6.99901Z' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.08, 1.44); }
  .burger-toggle-icon-active {
    transform: rotate(0); }
  .header__bottom {
    display: none; }
  .header__middle-callback-block {
    display: none; }
  .header__top-flex-container div {
    display: none; } }

:root {
  --swiper-navigation-size: 25px !important; }

.hero {
  position: relative;
  margin-bottom: 30px; }
  .hero__slider-container {
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1; }
  .hero__slide {
    height: auto; }
  .hero__slide-content {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%; }
  .hero__arrow-next {
    background-color: rgba(255, 255, 255, 0.459);
    padding: 18px;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out; }
  .hero__arrow-prev {
    background-color: rgba(255, 255, 255, 0.459);
    padding: 18px;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out; }
  .hero__arrow-next:hover {
    background-color: #ffffffbe; }
  .hero__arrow-prev:hover {
    background-color: #ffffffbe; }
  .hero__arrow-next::after {
    font-size: 24px; }
  .hero__arrow-prev::after {
    font-size: 24px; }
  .hero__slide-width-img {
    top: 25%;
    right: 3%;
    max-width: 200px;
    max-height: 200px; }
  .hero__slide-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 45px;
    border-radius: 6px;
    background-color: transparent;
    overflow: hidden;
    height: 160px; }
  .hero__slide-first {
    background: #ddffff; }
  .hero__slide-two {
    background: #e3efff; }
  .hero__slide-three {
    background: #ecebff; }
  .hero__slide-four {
    background: #999abf; }
  .hero__slide-five {
    background: #d9daff; }
  .hero__slide-six {
    background: #92bfab; }
  .hero__slide-seven {
    background: #d3ffeb; }
  .hero__slide-eight {
    background: #ffecd3; }
  .hero__slide-nine {
    background: #f0e0ca; }

.swiper-container {
  width: 100%;
  height: 100%; }

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain; }

.hero__slide-content {
  max-width: 65vw; }

.hero__title-slider {
  z-index: 10;
  font-size: 20px;
  font-weight: 700; }

.hero__slide-desc {
  font-size: 14px;
  max-width: 90%; }

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0px !important;
  bottom: 0;
  left: auto;
  border-radius: 0; }

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0 !important;
  background-color: transparent; }

.hero__arrow-prev:hover {
  background-color: #ffffff54; }

.hero__arrow-prev {
  border-radius: 0; }

.hero__arrow-next:hover {
  background-color: #ffffff50; }

.hero__arrow-next {
  background-color: transparent; }

.hero__arrow-prev:focus-visible {
  border-radius: 0;
  border: none;
  outline: transparent; }

.hero__arrow-next:focus-visible {
  border-radius: 0;
  border: none;
  outline: transparent; }

@media (max-width: 768px) {
  img.hero__slide-width-img {
    z-index: 5;
    display: none; }
  .hero__slide-content {
    max-width: 100%; }
  .hero__slide-container {
    padding: 10px 35px; } }

@media (max-width: 650px) {
  img.hero__title-slider {
    font-size: 26px; }
  .hero__slide-container {
    min-height: 190px; } }

@media (max-width: 420px) {
  .hero__title-slider {
    font-size: 18px; }
  .lineOne {
    margin-bottom: 10px; } }

.first-window {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 30px;
  background-image: url("https://images.unsplash.com/photo-1576613109753-27804de2cba8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVwYWlyJTIwcGhvbmV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .first-window__data {
    display: grid;
    gap: 7rem;
    grid-template-columns: 1.3fr 0.8fr; }
  .first-window__data form {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 15px 30px;
    border-radius: 6px;
    backdrop-filter: blur(5px);
    background-color: rgba(238, 238, 238, 0.716);
    box-shadow: 0 0 5px rgba(233, 233, 233, 0.904); }
  .first-window__data form h3 {
    text-align: center;
    margin-bottom: 1rem; }
  .first-window__content {
    color: #ffffff;
    padding-top: 25px;
    padding-left: 10px;
    border-radius: 6px;
    backdrop-filter: blur(3px);
    background-color: #2a2a2a26;
    height: fit-content;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.157); }
  .first-window__title {
    font-size: 3rem;
    font-weight: 800; }
  @media (max-width: 1024px) {
    .first-window__title {
      font-size: 2rem; }
    .first-window__data {
      gap: 2rem; } }
  @media (max-width: 924px) {
    .first-window__data {
      grid-template-columns: 1fr; }
    .first-window__content {
      text-align: center;
      max-width: 667px;
      margin: 0 auto; }
    .first-window__data form {
      max-width: 414px;
      margin: 0 auto; } }
  @media (max-width: 768px) {
    .first-window__content p {
      font-size: .8rem; } }
  @media (max-width: 490px) {
    .first-window__title {
      font-size: 1.6rem; } }

.often-request {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px; }
  .often-request__list {
    margin-top: 5px !important; }
  .often-request__item {
    height: 5vh;
    width: auto !important; }
  .often-request__link {
    font-size: 12px;
    padding: 3px 8px;
    border: 1px solid #0874c07a;
    border-radius: 6px;
    color: #000;
    transition: background-color .2s ease-in-out; }
  .often-request__link:hover {
    border: 1px solid #0874c07a;
    background-color: #0a74c0;
    color: #fff; }

.often-request__slider {
  min-height: 14px; }

.often-arrow-next {
  color: #0a74c0;
  box-shadow: 0 0 10px #0a74c0;
  top: 4px !important;
  height: 0 !important;
  border-radius: 100%;
  background: white;
  padding: 18px;
  transition: background .2s ease-in-out; }

.often-arrow-prev {
  color: #0a74c0;
  box-shadow: 0 0 10px #0a74c0;
  top: 3px !important;
  height: 0 !important;
  border-radius: 100%;
  background: white;
  padding: 18px;
  transition: background .2s ease-in-out; }

.often-arrow-next:active {
  color: #ffffff;
  background: #1580cd; }

.often-arrow-prev:active {
  color: #ffffff;
  background: #1580cd; }

.often-arrow-prev::after {
  font-size: 12px; }

.often-arrow-next::after {
  font-size: 12px; }

.often-arrow-prev.swiper-button-disabled {
  opacity: 0; }

.often-arrow-next.swiper-button-disabled {
  opacity: 0; }

@media (max-width: 992px) {
  .often-request__link {
    color: #000; }
  .often-request__link:hover {
    background-color: #fff;
    color: #000; }
  .often-request__link:active {
    background-color: #fff;
    color: #0a74c0; } }

.services {
  margin-bottom: 30px; }
  .services__list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px; }
  .services__item {
    position: relative;
    min-height: 100px;
    border-radius: 6px;
    background: #e1f6ff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    transition: background .3s ease-in-out; }
  .services__item:hover {
    background: #b1e0ff; }
  .services__item-img {
    margin-bottom: 15px; }
  .services a {
    display: block;
    color: black;
    text-align: center;
    font-size: .8rem;
    font-weight: 400;
    transition: color .2s ease-in; }
  .services a::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    content: ""; }
  @media (max-width: 1100px) {
    .services__list {
      grid-template-columns: repeat(3, 1fr); } }
  @media (max-width: 600px) {
    .services__list {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 375px) {
    .services__list {
      grid-template-columns: 1fr; } }

.trust {
  margin-bottom: 30px; }
  .trust__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px; }
  .trust__item {
    background-color: #ffffff;
    max-width: 100%;
    padding: 0 5px 0 5px;
    padding-top: 95px;
    text-align: center;
    box-shadow: 0px 0px 10px #efefefa3;
    border-radius: 6px; }
  .trust__item h3 {
    font-size: 1.3rem;
    font-weight: 800; }
  .trust__item p {
    font-size: .85rem; }

.trust__qval-bg {
  background-image: url(../img/qval.svg);
  background-repeat: no-repeat;
  background-position: center 15px;
  background-size: 60px; }

.trust__term-bg {
  background-image: url(../img/term.svg);
  background-repeat: no-repeat;
  background-position: center 15px;
  background-size: 60px; }

.trust__proffesional-bg {
  background-image: url(../img/professional.svg);
  background-repeat: no-repeat;
  background-position: center 15px;
  background-size: 60px; }

.trust__possibilities-bg {
  background-image: url(../img/possibilities.svg);
  background-repeat: no-repeat;
  background-position: center 15px;
  background-size: 60px; }

@media (max-width: 860px) {
  .trust__list {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 650px) {
  .trust__list {
    grid-template-columns: repeat(1, 1fr); } }

.types {
  position: relative;
  padding: 15px 0; }
  .types__card-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px; }
  .types__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    background: transparent;
    padding-bottom: 15px; }
  .types__text-wrapper {
    margin-top: 5px; }
  .types__item-title {
    font-size: 1.3rem;
    font-weight: 800;
    max-width: 180px;
    margin-top: 20px;
    margin-bottom: 20px; }
  .types__item-desc {
    margin-bottom: 30px;
    padding-right: 15px;
    font-size: 0.85rem; }
  .types__item-content-price {
    font-family: "Nunito", sans-serif, serif; }
  .types__item-price {
    color: #0a74c0;
    font-size: 1.3rem;
    font-weight: 600;
    display: block;
    margin-bottom: 25px; }
  .types__item-content-container img {
    width: 100%; }
  .types-trigger {
    z-index: 1001;
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    background-color: #00000093;
    color: black;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out; }
  .types-trigger-active {
    display: flex; }
  .types-trigger__container {
    z-index: 1002;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 30px 50px;
    max-width: 600px;
    background-color: #fff;
    padding: 30px 50px;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out; }
  .types-trigger__container-active {
    transform: translateX(0%); }
  .types-trigger__close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 0.5px; }

.form__alert-text {
  font-size: .8rem;
  text-align: center; }

.form__alert {
  margin-top: 15px;
  padding: 4px;
  border-radius: 6px;
  transition: opacity .2s ease-in-out; }

.form__alert--danger {
  background-color: #ff9b9b; }

.form__alert--success {
  background-color: #b8ffcc; }

.btn-types {
  display: inline-block;
  padding: 8px 10px;
  max-width: 150px;
  margin-left: 10px; }

@media (max-width: 650px) {
  .types__card-list {
    grid-template-columns: repeat(1, 1fr); } }

.offer-calc {
  padding: 20px 0;
  background-image: url(../img/pattern-offer-calc.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .offer-calc__container {
    display: flex;
    justify-content: center;
    align-items: center; }
  .offer-calc__data-wrap {
    width: 50%;
    padding: 20px 40px;
    background-color: rgba(252, 252, 252, 0.6);
    border-radius: 10px;
    box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.25); }
  .offer-calc__title {
    text-align: center;
    color: #0a74c0; }
  .offer-calc__desc {
    text-align: center;
    font-size: 14px;
    padding: 5px 5%;
    font-weight: 200; }
  .offer-calc__h3-wrap {
    border-radius: 10px;
    padding: 10px 20px;
    text-align: center;
    background: linear-gradient(266.61deg, #ae15c7 -7.95%, #0a74c0 104.29%); }
  .offer-calc__title-h3 {
    color: #ffffff; }
  .offer-calc__h3-desc {
    color: #ffffff;
    font-weight: 200;
    font-size: 12px; }
  .offer-calc__form {
    padding: 15px 0; }
  .offer-calc__lable-form {
    display: block;
    margin-bottom: 0.4rem;
    color: #0a74c0;
    padding-left: 1rem;
    font-weight: 300;
    font-size: 0.8rem; }
  .offer-calc__select ~ .choices__list--single {
    color: #0a74c0;
    font-size: 0.8rem; }
  .offer-calc__select-container:not(:last-child) {
    margin-bottom: 18px; }
  .offer-calc__select-wrapper > .choices > .choices__inner {
    border: 2px solid #0a74c0;
    padding: 0;
    padding: 10px; }
  .offer-calc__rules {
    font-size: 0.7rem;
    font-weight: 100;
    text-align: center; }
  .offer-calc__price-wrap {
    padding: 10px 0;
    text-align: center;
    color: #0a74c0;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: "Nunito", sans-serif, sans-serif; }
  .offer-calc__price-wrap p {
    padding: 0;
    margin: 0; }
  .offer-calc__price-wrap p:not(:last-child) {
    margin-bottom: 5px; }
  .offer-calc__price-sell {
    font-size: 1.3rem;
    color: #ff2969; }
  .offer-calc__phone-input {
    border: 2px solid #0a74c0;
    width: 100%;
    padding: 10px;
    border-radius: 50px; }
  .offer-calc__btn {
    background: linear-gradient(266.61deg, #ae15c7 -7.95%, #0a74c0 104.29%);
    width: 100%;
    color: #ffffff;
    font-family: "Nunito", sans-serif, sans-serif;
    font-size: 1.1rem;
    font-weight: 700;
    text-align: center;
    border-radius: 50px; }
  .offer-calc__btn:hover {
    background: linear-gradient(266.61deg, #be2fd4 -7.95%, #280ac0 104.29%);
    color: #ffffff; }
  .offer-calc__btn:active {
    background: #000000;
    color: #ffffff; }
  @media (max-width: 860px) {
    .offer-calc__data-wrap {
      width: 70%; } }
  @media (max-width: 600px) {
    .offer-calc__data-wrap {
      width: 100%; }
    .offer-calc__title {
      font-size: 1.4rem; }
    .offer-calc__title-h3 {
      font-size: 1.1rem; } }

.review {
  margin-bottom: 30px;
  padding: 15px 0; }
  .review__slider-container {
    display: flex;
    justify-content: center; }
  .review__list-wrapper {
    position: relative; }
  .review__slide {
    min-height: 50vh;
    padding: 15px 0; }
  .review__slide-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .review__slide-desc {
    text-align: center;
    max-width: 760px;
    margin-bottom: 25px; }
  .review__client-name {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 50px; }
  .review__yandex {
    z-index: 10;
    position: absolute;
    bottom: 15%; }
  .review__arrow-next {
    color: #8D8D8D;
    background-color: rgba(255, 255, 255, 0.459);
    padding: 25px;
    border: 1px solid #8D8D8D;
    border-radius: 50%;
    transition: color .2s ease-in-out, border .2s ease-in-out; }
  .review__arrow-prev {
    color: #8D8D8D;
    background-color: rgba(255, 255, 255, 0.459);
    padding: 25px;
    border: 1px solid #8D8D8D;
    border-radius: 50%;
    transition: color .2s ease-in-out, border .2s ease-in-out; }
  .review__arrow-next:hover {
    color: #0a74c0;
    border: 1px solid #0a74c0;
    background-color: #ffffffbe; }
  .review__arrow-prev:hover {
    color: #0a74c0;
    border: 1px solid #0a74c0;
    background-color: #ffffffbe; }
  .review__arrow-next::after {
    font-size: 24px; }
  .review__arrow-prev::after {
    font-size: 24px; }
  .review__yandex-container {
    display: flex;
    justify-content: center; }

.reviews-slider {
  min-height: 50vh; }

.work {
  margin-bottom: 30px; }
  .work__list {
    display: grid;
    justify-content: space-between;
    align-items: stretch;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px; }
  .work__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    padding: 20px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;
    margin-bottom: 30px; }
  .work__item::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 110%;
    max-width: 110%;
    background-color: #1580cd;
    transform: translateY(-100%);
    transition: background-color 0.4s cubic-bezier(0, 1, 0, 1), color 0.4s cubic-bezier(0, 1, 0, 1), transform 0.4s cubic-bezier(0, 1, 0, 1); }
  .work__item:hover::before {
    content: "";
    position: absolute;
    transform: translateY(0); }
  .work__text-wrapper {
    margin-bottom: 30px;
    z-index: 5; }
  .work__title {
    font-size: 18px;
    max-width: 310px;
    margin-bottom: 30px; }
  .work__price {
    font-family: "Nunito", sans-serif, sans-serif;
    font-weight: 700;
    display: block;
    color: #0a74c0;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0 15px 0; }
  .work__desc {
    max-width: 310px;
    font-size: .85rem; }
  .work__btn {
    font-weight: 600; }
  .work__price-wrapper {
    z-index: 5; }
  .work__item:hover .work__btn {
    color: #0a74c0;
    background-color: #ffffff; }
  .work__item:hover .work__btn:hover {
    color: #02010e; }
  .work__item:hover .work__btn:active {
    background-color: #d8d8d8; }
  .work__item:hover .work__price {
    color: #ffffff; }
  .work__item:hover .work__text-wrapper {
    color: #ffffff; }
  .work__zamena-stekla {
    background-image: url(../img/zamena-stekla.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover; }
  .work__zamena-akb {
    background-image: url(../img/zamena-akb.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover; }
  .work__zamena-modylia {
    background-image: url(../img/zamena-modylia.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover; }
  .work__popadanie-vody {
    background-image: url(../img/popadanie-vody.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover; }
  .work__termo-nout {
    background-image: url(../img/termo-nout.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover; }
  .work__remont-kartridj {
    background-image: url(../img/remont-kartridj.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover; }

@media (max-width: 860px) {
  .work__list {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 650px) {
  .work__list {
    grid-template-columns: repeat(1, 1fr); } }

.border-green {
  border: 1px solid #76BC21;
  border-radius: 4px; }

.с-green-light {
  color: #76BC21; }

.border-orange {
  border: 1px solid #FE7200;
  border-radius: 4px; }

.с-orange {
  color: #FE7200; }

.product {
  margin-bottom: 30px;
  padding: 15px 0; }
  .product__special-offer {
    padding: 4px;
    font-size: 0.7rem;
    font-weight: 500;
    width: 65%; }
  .product__list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin: 0 auto; }
  .product__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 20px 10px;
    transition: all 0.2s ease-in-out; }
  .product__item p {
    margin: 0;
    margin-bottom: 15px; }
  .product__char-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
  .product__char-item {
    font-weight: 500;
    font-size: 10px;
    color: #0a74c0;
    text-transform: uppercase;
    padding: 2px 4px;
    border-radius: 4px; }
  .product__avatar-container {
    display: flex;
    justify-content: center;
    height: 200px;
    margin-bottom: 20px; }
  .product__img-avatar {
    object-fit: contain; }
  .product__avatar {
    margin: 0 auto; }
  .product__rating {
    margin-bottom: 15px; }
  .product__title {
    font-size: 16px;
    font-weight: 600;
    padding-right: 15px;
    margin-bottom: 15px;
    margin-top: 15px; }
  .product__price-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .product__price-container {
    font-family: "Nunito", sans-serif serif;
    display: flex;
    flex-direction: column; }
  .product__old-price {
    font-size: 13px;
    color: #8D8D8D;
    font-weight: 400;
    margin-bottom: 10px;
    text-decoration: line-through; }
  .product__actual-price {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "Nunito", sans-serif; }
  .product__btn {
    border: 2px solid;
    font-weight: 600;
    padding: 1px 10px;
    height: 35px; }
  .product__btn:hover {
    color: #ffffff;
    border: 2px solid #0a74c0;
    background-color: #0a74c0;
    font-weight: 600;
    padding: 1px 10px;
    height: 35px; }
  .product__btn:active {
    border: 2px solid #000000;
    background-color: #000000;
    font-weight: 600;
    padding: 1px 10px;
    height: 35px; }
  .product__btn-more-container {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 80px; }
  .product__btn-more-container a {
    text-align: center; }
  .product__btn-more {
    display: inline-block;
    width: 320px; }
  .product__btn-more:hover {
    color: #fff;
    background-color: #0a74c0; }
  .product__btn-more:active {
    color: #fff;
    border: 1px solid #000000;
    background-color: #000000; }

.rating-star {
  background-image: url(../img/yellow-star.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: left; }

.filter__list {
  display: flex;
  margin-bottom: 10px; }

.filter__item {
  border: 1px solid #0a74c0;
  padding: 3px;
  color: #0a74c0;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer; }

.filter__item:not(:last-child) {
  margin-right: 6px; }

.hide-product {
  display: none; }

@media (max-width: 980px) {
  .product__list {
    grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 820px) {
  .product__list {
    grid-template-columns: repeat(2, 1fr); }
  .product__btn {
    width: 140px; }
  .product__actual-price {
    font-size: 20px; } }

@media (max-width: 650px) {
  .product__list {
    grid-template-columns: repeat(1, 1fr); }
  .product__btn-more {
    width: 100%; }
  .product__char-item {
    font-size: 14px; } }

.swiper {
  width: 100%;
  height: 100%; }

.product-slide {
  height: 100%; }

.product-slider {
  padding-top: 30px;
  padding-bottom: 40px; }
  .product-slider__wrap {
    height: 510px; }

.product-slider-btnstyle-next,
.product-slider-btnstyle-prev {
  padding: 10px;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  background: #efefef96;
  box-shadow: 0 0 14px 0px #2f7e9d8c; }

.product-slider-btnstyle-prev {
  left: 0.6rem !important; }

.product-slider-btnstyle-next {
  right: 0.6rem !important; }

.question-form {
  margin-bottom: 30px; }
  .question-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ebf6ff;
    border-radius: 6px;
    padding: 30px 0; }
  .question-form__title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .question-form__title {
    font-size: 26px;
    max-width: 1025px;
    text-align: center;
    margin-bottom: 10px; }
  .question-form__number-phone {
    font-size: 26px;
    font-weight: 600;
    color: #0a74c0;
    margin-bottom: 30px; }
  .question-form__descr {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    max-width: 625px;
    text-align: center; }
  .question-form__line-title {
    margin-bottom: 20px; }
  .question-form__container-form {
    max-width: 440px; }
  .question-form__form-data-container {
    display: flex;
    justify-content: space-between;
    width: 100%; }
  .question-form__wrapper-id-client {
    width: 100%; }
  .question-form__client-id-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
  .question-form__phone-input {
    padding-left: 10px;
    border-radius: 50px;
    border: 1px solid #8D8D8D;
    height: 41px;
    width: 100%; }
  .question-form__city-select {
    width: 100%; }
  .question-form__checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    text-align: center; }
  .question-form__checkbox-label {
    position: relative;
    font-size: 13px; }
  .question-form__link {
    color: #0a74c0; }
  .question-form__btn-submit {
    width: 100%; }
  .question-form__text-require {
    font-size: 10px;
    max-width: 360px;
    margin-top: 5px; }

input[data-validate-rules^="phone"] {
  padding-left: 10px; }

.js-validate-error-label {
  margin-top: 10px;
  font-size: 12px;
  color: #ff2969;
  max-width: 300px; }

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: .75rem;
  font-weight: 300;
  padding-left: 0.8rem; }

.choices {
  width: 100%; }

.choices__inner {
  border: 1px solid #8D8D8D;
  border-radius: 50px;
  min-height: auto;
  transition: box-shadow .1s ease-in-out; }

.is-open .choices__inner {
  border-radius: 6px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.295); }

.is-focused .choices__inner {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25); }

.choices__list--dropdown {
  border-radius: 6px;
  border: 1px solid #0a74c0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25); }

.choices__list--dropdown .is-active {
  border-radius: 6px;
  border: 1px solid #0a74c0; }

.choices__heading {
  color: #0a74c0;
  font-weight: 600;
  font-size: 16px; }

.choices__list--dropdown .choices__item {
  font-size: 12px; }

.choices__item--selectable::after {
  color: #0a74c0;
  background-color: #fff;
  padding: 2px 4px;
  border-radius: 4px; }

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 1; }

#choices--selectModel-item-choice-1 {
  display: none; }

.choices__list--multiple .choices__item {
  background-color: #0a74c0;
  border: 1px solid #0a74c0; }

.calc-pop-up {
  position: relative;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  position: absolute;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(105, 105, 105, 0.5);
  transition: display .3s ease-in-out;
  z-index: 199; }

.calculateApplication-popUp__container {
  display: none;
  padding: 0 15px;
  width: 55%;
  height: 80%;
  overflow: auto;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  border-radius: 6px;
  z-index: 220; }

.popUp__container-is-active {
  display: block; }

.calculateApplication-popUp__content-wrapper {
  position: relative;
  display: flex; }

.calcapp-popup__close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #0a74c0;
  border-radius: 4px;
  background-image: url(../img/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px; }

.calcapp-popup__close:hover {
  background-color: #0a74c0;
  border: 1px solid #0a74c0;
  border-radius: 4px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.64341 6.99901L13.6552 1.99813C13.8747 1.77862 13.998 1.48091 13.998 1.17048C13.998 0.860046 13.8747 0.562331 13.6552 0.342824C13.4358 0.123318 13.1381 0 12.8277 0C12.5173 0 12.2196 0.123318 12.0002 0.342824L7 5.35536L1.99983 0.342824C1.78036 0.123318 1.48268 -2.31288e-09 1.1723 0C0.861913 2.31288e-09 0.56424 0.123318 0.344765 0.342824C0.125289 0.562331 0.00198911 0.860046 0.00198911 1.17048C0.00198911 1.48091 0.125289 1.77862 0.344765 1.99813L5.35659 6.99901L0.344765 11.9999C0.235521 12.1083 0.148811 12.2372 0.0896384 12.3792C0.0304655 12.5213 0 12.6736 0 12.8275C0 12.9814 0.0304655 13.1338 0.0896384 13.2758C0.148811 13.4179 0.235521 13.5468 0.344765 13.6552C0.453117 13.7644 0.582027 13.8512 0.724059 13.9103C0.866091 13.9695 1.01843 14 1.1723 14C1.32616 14 1.47851 13.9695 1.62054 13.9103C1.76257 13.8512 1.89148 13.7644 1.99983 13.6552L7 8.64265L12.0002 13.6552C12.1085 13.7644 12.2374 13.8512 12.3795 13.9103C12.5215 13.9695 12.6738 14 12.8277 14C12.9816 14 13.1339 13.9695 13.2759 13.9103C13.418 13.8512 13.5469 13.7644 13.6552 13.6552C13.7645 13.5468 13.8512 13.4179 13.9104 13.2758C13.9695 13.1338 14 12.9814 14 12.8275C14 12.6736 13.9695 12.5213 13.9104 12.3792C13.8512 12.2372 13.7645 12.1083 13.6552 11.9999L8.64341 6.99901Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px; }

.popup-active {
  opacity: 1;
  pointer-events: auto; }

.calc__pop-up__content-container {
  margin-top: 50px;
  margin-left: 50px;
  display: flex;
  flex-direction: column; }

@media (max-width: 650px) {
  .question-form__container-form {
    padding: 0 14%; } }

.cost-now {
  margin-bottom: 20px; }
  .cost-now__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 160px 50px;
    background-image: url("../img/cost-now-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .cost-now__title {
    color: #ffffff; }
  .cost-now__data {
    max-width: 50%; }
  .cost-now__imag-wrap {
    width: 30%; }
  .cost-now__imag-wrap img {
    width: 100%; }
  .cost-now__form {
    width: 70%; }
  .cost-now__lable-form {
    padding-left: 1rem;
    margin-bottom: 0.4rem;
    color: #ffffff;
    font-size: .8rem;
    font-weight: 300; }
  .cost-now__btn {
    border: none;
    border-radius: 50px;
    background-color: #AE15C7;
    height: 42px;
    color: #ffffff;
    font-weight: 700;
    font-size: 1.2rem;
    box-shadow: 0 4px 10px 5px #2a2a2a29;
    transition: background-color .1s ease-in-out; }
  .cost-now__btn:hover {
    border: none;
    border-radius: 50px;
    background-color: #c54ad8;
    height: 42px;
    color: #ffffff;
    font-weight: 700;
    font-size: 1.2rem; }
  @media (max-width: 1024px) {
    .cost-now__data {
      max-width: 70%; }
    .cost-now__form {
      width: 80%; }
    .cost-now__imag-wrap {
      width: 30%; } }
  @media (max-width: 820px) {
    .cost-now__data {
      max-width: 80%; }
    .cost-now__imag-wrap {
      display: none; } }
  @media (max-width: 650px) {
    .cost-now__data {
      max-width: 100%; }
    .cost-now__form {
      width: 100%; }
    .cost-now__container {
      padding-top: 130px;
      padding-bottom: 35px;
      padding-left: 10px;
      padding-right: 10px; } }

.acc-map {
  padding: 15px 0;
  margin-bottom: 30px; }
  .acc-map__wrapper {
    display: flex;
    flex-direction: column; }
  .acc-map__map-container {
    margin-top: 30px;
    border-radius: 6px; }
  .acc-map-red {
    background: red; }
  .acc-map-blue {
    background: #12e4e4; }

.swiper-accord-slider {
  width: 600px;
  height: 300px; }

/* acc */
.catalog-tabs__profile-acc {
  width: 50%; }

.acc-inner {
  margin-top: 0px; }

.acc-item__button {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  border-top: 1px solid #02010e;
  border-bottom: 1px solid #02010e;
  font-size: 20px;
  text-align: left;
  font-weight: 700;
  min-height: 70px;
  margin: 0; }

.acc-item__button:hover > .acc-item__title {
  color: #0a74c0; }

.acc-item__title {
  color: #02010e;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  margin-right: 30px;
  transition: .2s ease-in-out; }

.ui-accordion-header-active > .acc-item__title {
  color: #0a74c0; }

.acc-item__text {
  margin: 0;
  font-size: 14px;
  padding: 1em 2.2em;
  font-weight: 600;
  border-top: 0;
  overflow: auto;
  border-bottom: 1px solid #8D8D8D; }

.acc-item__accord {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #0a74c0;
  background-color: transparent;
  background-image: url("/img/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  transform: rotate(45deg);
  transition: transform 0.2s cubic-bezier(0.42, 0, 0.08, 1.44), background-color 0.3s cubic-bezier(0.42, 0, 0.08, 1.44), border 0.3s cubic-bezier(0.42, 0, 0.08, 1.44), border-top 0.3s cubic-bezier(0.42, 0, 0.08, 1.44), border-bottom 0.3s cubic-bezier(0.42, 0, 0.08, 1.44), color 0.3s cubic-bezier(0.42, 0, 0.08, 1.44); }

.acc-item__button:hover > .acc-item__accord {
  background-color: transparent; }

.acc-item__accord-active {
  background-image: url("/img/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #0a74c0;
  transform: rotate(90deg); }

/* переопределяем стили которые задаёт jqueryUI */
.ui-accordion .ui-accordion-header {
  margin-top: -1px; }

.ui-state-active {
  border-top: 1px solid #0a74c0;
  border-bottom: 1px solid #0a74c0; }

.acc-item__text-desc {
  display: block;
  max-width: 320px;
  margin-bottom: 5px; }

.mt20 {
  margin-top: 20px; }

.map-width {
  min-height: 50vh;
  width: 100%; }

[class*="ground-pane"] {
  filter: grayscale(50%); }

[class*="gotoymaps"],
[class*="gototech"],
[class*="copyrights"] {
  display: none; }

@media (max-width: 1020px) {
  .acc-map__wrapper {
    grid-template-columns: repeat(1, 1fr); }
  .map-width {
    min-width: 100%; } }

@media (max-width: 620px) {
  .acc-map__map-container {
    max-width: 100vw; } }

.social-block {
  color: #fff;
  margin-bottom: 30px;
  padding: 25px 0;
  background-color: #0a74c0;
  background-position-y: center;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain; }
  .social-block__wrapper-content-container {
    padding: 0px 0 0px 20px;
    border-radius: 6px;
    min-height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .social-block__title {
    font-weight: 500;
    color: white; }
  .social-block__description {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: white; }
  .social-block__social-list {
    display: flex;
    margin-bottom: 35px; }
  .social-block__social-link {
    border: 1px solid transparent; }
  .social-block__social-link:hover {
    border: 1px solid transparent; }
  .social-block__social-item:not(:last-child) {
    margin-right: 20px; }

.social-icon-in-section {
  display: block;
  background-color: #8888d1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 65px;
  height: 65px;
  background-size: 40%;
  border-radius: 12px;
  box-shadow: inset 0px 0px 25px #0a74c0;
  backdrop-filter: blur(100px);
  transition: box-shadow .1s ease-in-out, border .1s ease-in-out; }

.social-icon-in-section:hover {
  box-shadow: inset 0px 0px 7px #0a74c0;
  border: 1px solid #0a8ac063; }

.fixed-container__background {
  background-color: #e1f6ff;
  box-shadow: 0 0 15px rgba(168, 168, 168, 0.1); }

.fixed-container {
  position: relative;
  display: flex;
  min-height: 70vh;
  padding: 20px 0px;
  border-radius: 6px; }

.fixed-container__background:not(:last-child) {
  margin-bottom: 15px; }

.info-title {
  font-size: 26px;
  margin-bottom: 30px; }

.left-container-fixed {
  position: relative;
  width: 50vw; }

.left-container-info--fixed {
  position: sticky;
  top: 20vh; }

.right-container-fixed {
  width: 50vw; }

.left-container__fixed-title {
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 35px; }

.left-container__fixed-desc {
  font-size: 26px;
  font-weight: 600;
  padding-right: 15px;
  max-width: 350px; }

.right-container-fixed-desc {
  font-size: 16px;
  line-height: 24px; }

.fixed-container-line {
  margin-bottom: 10px; }

@media (max-width: 992px) {
  .fixed-container {
    flex-direction: column;
    height: auto; }
  .right-container-fixed {
    width: 100vw; } }

@media (max-width: 670px) {
  .left-container-fixed {
    position: relative;
    width: 90vw; }
  .left-container__fixed-desc {
    font-size: 22px; }
  .right-container-info {
    width: 90vw; } }

.phone-info-hero {
  margin-bottom: 30px; }

.phone-info__hero-info-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  min-height: 50vh; }

.phone-info__hero--background {
  width: 100%;
  background-image: url(../img/iphone-11-1-1.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain; }

.phone-info__hero-container h1 {
  margin-bottom: 40px; }

.phone-info__variant-list {
  position: relative;
  font-size: 20px;
  font-weight: 600; }

.phone-info__variant-item {
  margin-left: 18px; }

.phone-info__variant-item:not(:last-child) {
  margin-bottom: 15px; }

.phone-info__variant-item::before {
  content: '';
  position: absolute;
  left: 0;
  margin-top: 7px;
  width: 8px;
  height: 8px;
  background-color: #02010e; }

@media (max-width: 992px) {
  .phone-info__hero-container h1 {
    font-size: 22px; }
  .phone-info__variant-list {
    font-weight: 500;
    font-size: 16px; }
  .phone-info__variant-item::before {
    margin-top: 3px; } }

@media (max-width: 860px) {
  .phone-info__hero-container h1 {
    font-size: 18px; } }

@media (max-width: 670px) {
  .phone-info__hero--background {
    background-image: none !important; }
  .phone-info__hero-container h1 {
    font-size: 20px; }
  .phone-info__variant-list {
    max-width: 100vw; }
  .phone-info__hero-info-container {
    min-height: auto;
    padding: 45px 0; } }

.contacts-info {
  margin-bottom: 30px; }
  .contacts-info__desc {
    font-size: .9rem;
    line-height: 1.2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px; }
  .contacts-info__img {
    width: 100%; }
  .contacts-info__img--link {
    width: 100%; }

@media (max-width: 792px) {
  .contacts-info__desc {
    grid-template-columns: 1fr; } }

.opt-hero {
  color: #fff;
  background: black;
  min-height: 85vh;
  margin-bottom: 30px; }
  .opt-hero__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10%; }
  .opt-hero__content {
    max-width: 50%; }
  .opt-hero__title {
    color: #fff;
    line-height: 3rem; }
  .opt-hero__img-container {
    width: 40%; }
  .opt-hero__desc {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 50px;
    line-height: 2rem; }
  .opt-hero__reg-link {
    display: inline-block;
    margin-bottom: 30px;
    border-bottom: 1px solid transparent; }
  .opt-hero__autorize-link {
    display: block;
    color: #0a74c0; }

.master-price {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px; }

.opt-become {
  padding: 25px 0;
  margin-bottom: 30px; }
  .opt-become__list {
    display: grid;
    gap: 0 15px;
    grid-template-columns: repeat(4, 1fr); }
  .opt-become__item {
    padding-top: 80px;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 30%; }
  .opt-become__item-1 {
    background-image: url("../img/opt-step-1.svg"); }
  .opt-become__item-2 {
    background-image: url("../img/opt-step-2.svg"); }
  .opt-become__item-3 {
    background-image: url("../img/opt-step-3.svg"); }
  .opt-become__item-4 {
    background-image: url("../img/opt-step-4.svg"); }
  .opt-become__item-desc {
    font-size: .9rem; }
  @media (max-width: 982px) {
    .opt-become__list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr); }
    .opt-become__item {
      padding-top: 70px;
      background-size: 70px;
      margin-bottom: 30px; } }
  @media (max-width: 568px) {
    .opt-become__list {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(4, 1fr); }
    .opt-become__item-desc {
      padding-right: 30px; } }

.header__bottom {
  margin-bottom: 0; }

.sec {
  height: 300vh;
  display: flex; }

.sticky {
  position: sticky;
  top: 50px;
  align-self: flex-start; }

.fixed-call-btn__btn {
  position: fixed;
  bottom: 20px;
  margin: auto;
  left: calc(50vw - 104px);
  width: 200px;
  padding: 10px 0px;
  font-weight: 600;
  border-radius: 6px;
  z-index: 1000; }

.laser-page {
  margin-bottom: 30px; }

.gidrogel {
  margin-bottom: 30px;
  position: relative; }
  .gidrogel__benefit-info-img {
    object-fit: contain;
    width: 40vw; }
  .gidrogel__desc {
    max-width: 75vw;
    margin-bottom: 30px; }
  .gidrogel__benefit-info-title {
    text-align: center;
    margin-bottom: 30px; }
  .gidrogel__benefit-info {
    display: flex;
    flex-direction: column; }
  .gidrogel__benefit-info-wrapper {
    display: flex; }
  .gidrogel__benefit-info-list {
    counter-reset: list; }
  .gidrogel__benefit-info-item {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400; }
  .gidrogel__benefit-info-item::before {
    counter-increment: list;
    content: counter(list) ". "; }
  .gidrogel__benefit-info-span {
    display: block; }
  .gidrogel__benefit-info-span:not(:last-child) {
    margin-bottom: 20px; }
  @media (max-width: 787px) {
    .gidrogel__benefit-info-wrapper {
      display: flex;
      flex-direction: column-reverse; }
    .gidrogel__benefit-info-img {
      width: auto; }
    .gidrogel__desc {
      max-width: 100%; } }

.orders__block-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh 0; }

.orders__image-block {
  display: flex;
  justify-content: center;
  width: 50%; }

.orders_form-block {
  width: 50%; }

.orders__image {
  width: 50%; }

@media (max-width: 920px) {
  .orders__block-wrapper {
    flex-direction: column; }
  .orders__block-wrapper {
    height: auto; } }

.order-status {
  display: inline-block;
  color: #0a74c0;
  padding: 4px 0;
  margin-top: 5px;
  text-align: center;
  width: 100%;
  font-size: .8rem; }

.d-flex {
  display: flex; }

.justify-between {
  justify-content: space-between; }

.livesklad-link {
  display: none; }

.section-banner {
  background: linear-gradient(45deg, #76ccf7, #ffffff);
  border-radius: 6px;
  padding: 0 25px 0 25px;
  min-height: 50vh;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }
  .section-banner__h1 {
    font-weight: 600;
    max-width: 30vw;
    margin-bottom: 40px;
    z-index: 10; }
  .section-banner__desc {
    font-size: 14px;
    font-weight: 400;
    max-width: 30vw;
    z-index: 10; }
  .section-banner__img {
    display: block;
    width: 380px;
    height: 300px;
    object-fit: contain;
    z-index: 5; }
  @media (max-width: 992px) {
    .section-banner__img {
      width: 280px; }
    .section-banner__h1 {
      font-size: 28px;
      max-width: 80vw; } }
  @media (max-width: 798px) {
    .section-banner__img {
      position: absolute;
      top: 25px;
      right: 0;
      width: 240px; }
    .section-banner__desc {
      max-width: 50vw; } }
  @media (max-width: 650px) {
    .section-banner__img {
      display: none; }
    .section-banner__desc {
      font-size: 14px;
      font-weight: 400;
      max-width: 90vw; } }

.politics-info {
  margin-bottom: 30px; }
  .politics-info__desc {
    padding: 0px 20px; }

.simplebar-wrapper {
  height: 40vh;
  border: 2px solid #d4d4d4;
  box-shadow: 0 4px 15px #d4d4d4;
  border-radius: 6px; }

.simplebar-scrollbar::before {
  background-color: #0a74c0; }

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  /*фиксированное позиционирование*/
  position: fixed;
  /* координаты положения */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* фоновый цвет элемента */
  background: #ffffff;
  /* размещаем блок над всеми элементами на странице (это значение должно быть больше, чем у любого другого позиционированного элемента на странице) */
  z-index: 1001;
  background-image: url("/img/preload.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 5.8vw; }

.preloader__row {
  width: 45vw;
  height: 45vh;
  background-image: url(/img/preload-circle.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10vw;
  animation: preloader-rotate 7s infinite linear; }

@keyframes preloader-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0; }

.loaded .preloader {
  display: none; }

@media (max-width: 870px) {
  .preloader {
    background-size: 21vw; }
  .preloader__row {
    width: 90vw;
    height: 90vh;
    background-size: 35vw; } }

.our-prices {
  padding-top: 30px;
  padding-bottom: 60px; }
  .our-prices__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 15px; }
  .our-prices__list-opt {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px 15px; }
  .our-prices__item {
    font-size: .9rem;
    display: flex;
    justify-content: space-between;
    background: #f1f1f1;
    padding: 15px 20px;
    border-radius: 2px;
    box-shadow: 3px 4px 6px #D3D3D3;
    transition: background .2s ease-in-out; }
  .our-prices__item:hover {
    background: #b1e0ff; }
  .our-prices__item-inform--title {
    width: 50%; }
  .our-prices__item-inform--time {
    color: #8D8D8D; }
  .our-prices__item-inform--price {
    font-style: italic; }
  .our-prices__btn-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center; }
  .our-prices__opt-product__title {
    font-size: 0.9rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 14px; }
  .our-prices__actual-price {
    font-size: .8rem;
    font-weight: 500;
    padding: 4px 0; }
  .our-prices__mb {
    margin-bottom: 6px; }
  .our-prices__product-percent-discount {
    background-color: red;
    color: white;
    padding: 4px 6px; }
  .our-prices__price-btn-wrapper {
    padding: 10px 0;
    margin: 5px 0; }
  @media (max-width: 820px) {
    .our-prices__list {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px; }
    .our-prices__item {
      font-size: .8rem; }
    .our-prices__item-inform--title {
      width: 40%; } }
  @media (max-width: 580px) {
    .our-prices__list {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px; } }

.product-opt {
  padding: 40px 0; }
  .product-opt__item-wrap:not(:last-child) {
    margin-bottom: 30px; }

.product__item--hidden-akb--opt:nth-child(n+5) {
  display: none; }

.product__item--hidden-lcd--opt:nth-child(n+5) {
  display: none; }

.opt-btn {
  display: block;
  text-align: center; }

.inline-rub {
  display: inline; }

.master-price--old {
  text-decoration: line-through;
  margin-bottom: 6px;
  font-weight: 400;
  color: #8D8D8D; }

.ui-tabs-nav {
  display: flex;
  justify-content: space-between;
  gap: 0 30px;
  margin-bottom: 30px; }

.ui-tabs-tab {
  border: none;
  width: 100%;
  font-size: .9rem; }

.ui-tabs-tab a {
  display: inline-block;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  background-color: #e8e8e8;
  color: #0a74c0;
  text-align: center;
  transition: background-color .2s ease-in-out, color .2s ease-in-out; }

.ui-tabs-active a {
  background-color: #0a74c0;
  color: #fff; }

@media (max-width: 864px) {
  .ui-tabs-tab {
    border: none;
    font-size: .7rem; }
  .ui-tabs-nav {
    justify-content: center; } }

.opt-akb {
  padding: 10px;
  background-color: #ccffd6;
  border-radius: 10px; }

.disabled-btn {
  background: #d0d0d0;
  color: #868686;
  border: 1px solid transparent; }

.disabled-btn:hover {
  background: #d0d0d0;
  color: #868686;
  border: 1px solid transparent; }

.alert-mass {
  display: block;
  text-align: center;
  width: 100%;
  font-family: 'Jura', sans-serif;
  font-weight: 600;
  padding: 3px 10px;
  border-radius: 4px;
  margin-top: 1rem;
  display: inline-block; }

.alert-mass--text {
  font-family: "Nunito", sans-serif, serif;
  font-size: .9rem;
  font-weight: 300;
  color: #000000; }

.bg-danger {
  background-color: #f99c9c; }

.bg-success {
  background-color: #ccffc9; }

.footer {
  padding-top: 40px;
  padding-bottom: 20px;
  background-color: #062D3E; }
  .footer__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px; }
  .footer_img-logo {
    max-width: 160px; }
  .footer__nav-title {
    margin-bottom: 18px;
    font-weight: 600;
    font-size: 16px;
    color: #fff; }
  .footer__nav-list {
    display: flex;
    flex-direction: column; }
  .footer__nav-item:not(:last-child) {
    margin-bottom: 8px; }
  .footer__nav-link {
    font-size: 12px;
    font-weight: 400;
    color: #D3D3D3;
    transition: color 0.2s ease-in-out; }
  .footer__nav-link:hover {
    color: #8D8D8D; }
  .footer__connect-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .footer__number-wrapper {
    margin-bottom: 35px; }
  .footer__number-link {
    display: block;
    font-family: "Nunito", sans-serif;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px; }
  .footer__social-list {
    display: flex;
    margin-bottom: 35px; }
  .footer__social-item:not(:last-child) {
    margin-right: 20px; }
  .footer__down-container {
    display: flex;
    flex-direction: column;
    padding: 15px 0; }
  .footer__down-content-wrapper {
    color: #8D8D8D;
    font-size: 12px;
    font-weight: 400;
    transition: color 0.2s ease-in-out; }
  .footer__down-ui-code-link {
    border-bottom: 1px solid #8D8D8D;
    transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out; }
  .footer__down-ui-code-link:hover {
    color: #D3D3D3;
    border-bottom: 1px solid #D3D3D3; }

.footer-title-ya-rating {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: end; }

.rating-yandex {
  display: flex;
  justify-content: flex-end; }

.close-point-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(97, 97, 97, 0.456);
  display: none;
  justify-content: center; }

.c-point-container__active {
  display: flex !important;
  overflow-y: hidden; }

.close-point__info-container {
  width: 80%;
  padding: 50px 0 20px 0;
  position: relative; }

.close-point__btn-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: red;
  color: white;
  border: none; }

.close-point__info-block {
  position: relative;
  padding: 40px 20px 70px 20px;
  background-color: #fdfdfd;
  box-shadow: 0 0 15px 5px rgba(31, 31, 31, 0.331); }

@media (max-width: 820px) {
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    justify-content: space-between; }
  .footer__connect-wrapper {
    align-items: flex-start; }
  .footer__logo-link {
    order: 3; }
  .footer-title-ya-rating {
    text-align: start; }
  .rating-yandex {
    justify-content: flex-start; } }

@media (max-width: 500px) {
  .footer__container {
    grid-template-columns: repeat(1, 1fr); }
  .footer__logo-link {
    order: 0; } }
