.work {
    margin-bottom: $sec-bottom;
    &__list {
        display: grid;
        justify-content: space-between;
        align-items: stretch;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }
    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
        // не менять радиус из-за картинок
        padding: 20px 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;
    }
    &__item::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        min-height: 110%;
        max-width: 110%;
        background-color: $blue-hover;
        transform: translateY(-100%);
        transition: background-color .4s cubic-bezier(0, 1, 0, 1), color .4s cubic-bezier(0, 1, 0, 1), transform .4s cubic-bezier(0, 1, 0, 1);
    }
    &__item:hover::before {
        content: "";
        position: absolute;
        transform: translateY(0);
    }
    &__text-wrapper {
        margin-bottom: 30px;
        z-index: 5;
    }
    &__title {
        font-size: 18px;
        max-width: 310px;
        margin-bottom: 30px;
    }
    &__price {
        font-family: $firstFont, sans-serif;
        font-weight: 700;
        display: block;
        color: $blue;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 0 15px 0;
    }
    &__desc {
        max-width: 310px;
        font-size: .85rem;
    }
    &__btn {
        font-weight: 600;
    }
    &__price-wrapper {
        z-index: 5;
    }
    &__item:hover &__btn {
        color: $blue;
        background-color: #ffffff;
    }
    &__item:hover &__btn:hover {
        color: $dark;
    }
    &__item:hover &__btn:active {
        background-color: #d8d8d8;
    }
    &__item:hover &__price {
        color: #ffffff;
    }
    &__item:hover &__text-wrapper {
        color: #ffffff;
    }
    &__zamena-stekla {
        background-image: url(../img/zamena-stekla.jpg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
    }
    &__zamena-akb {
        background-image: url(../img/zamena-akb.jpg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
    }
    &__zamena-modylia {
        background-image: url(../img/zamena-modylia.jpg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
    }
    &__popadanie-vody {
        background-image: url(../img/popadanie-vody.jpg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
    }
    &__termo-nout {
        background-image: url(../img/termo-nout.jpg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
    }
    &__remont-kartridj {
        background-image: url(../img/remont-kartridj.jpg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
    }
}

@media (max-width: 860px) {
    .work__list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 650px) {
    .work__list {
        grid-template-columns: repeat(1, 1fr);
    }
}
