.social-block {
    color: #fff;
    margin-bottom: 30px;
    padding: 25px 0;
    background-color: $blue;
    // background: url(/img/social_bg_transparent.png);
    background-position-y: center;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: contain;
    // filter: brightness(0.5);
    &__wrapper-content-container {
        padding: 0px 0 0px 20px;
        border-radius: $radius;
        min-height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__title {
        font-weight: 500;
        color: rgb(255, 255, 255);
    }
    &__description {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        color: rgb(255, 255, 255);
    }
    // social-icon
    &__social-list {
        display: flex;
        margin-bottom: 35px;
    }
    &__social-link {
        border: 1px solid transparent;
    }
    &__social-link:hover {
        border: 1px solid transparent;
    }
    &__social-item:not(:last-child) {
        margin-right: 20px;
    }
}

.social-icon-in-section {
    display: block;
    background-color: rgb(136, 136, 209);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 65px;
    height: 65px;
    background-size: 40%;
    border-radius: 12px;
    box-shadow: inset 0px 0px 25px #0a74c0;
    backdrop-filter: blur(100px);
    transition: box-shadow .1s ease-in-out, border .1s ease-in-out;
}

.social-icon-in-section:hover {
    box-shadow: inset 0px 0px 7px $blue;
    border: 1px solid #0a8ac063;
}
