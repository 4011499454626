:root {
  --swiper-navigation-size: 25px !important;
}

.hero {
  position: relative;
  margin-bottom: $sec-bottom;

  &__slider-container {
    border-radius: $br-10;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

  &__slide {
    height: auto;
  }

  &__slide-content {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  &__arrow-next {
    background-color: rgba(255, 255, 255, 0.459);
    padding: 18px;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;
  }

  &__arrow-prev {
    background-color: rgba(255, 255, 255, 0.459);
    padding: 18px;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;
  }

  &__arrow-next:hover {
    background-color: #ffffffbe;
  }

  &__arrow-prev:hover {
    background-color: #ffffffbe;
  }

  &__arrow-next::after {
    font-size: 24px;
  }

  &__arrow-prev::after {
    font-size: 24px;
  }

  &__slide-width-img {
    // position: absolute;
    top: 25%;
    right: 3%;
    max-width: 200px;
    max-height: 200px;
  }

  &__slide-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 45px;
    border-radius: $radius;
    background-color: transparent;
    overflow: hidden;
    height: 160px;
  }

  &__slide-first {
    background: #ddffff;
  }

  &__slide-two {
    background: #e3efff;
  }

  &__slide-three {
    background: #ecebff;
  }

  &__slide-four {
    background: #999abf;
  }

  &__slide-five {
    background: #d9daff;
  }

  &__slide-six {
    background: #92bfab;
  }

  &__slide-seven {
    background: #d3ffeb;
  }

  &__slide-eight {
    background: #ffecd3;
  }

  &__slide-nine {
    background: #f0e0ca;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hero__slide-content {
  max-width: 65vw;
}

.hero__title-slider {
  z-index: 10;
  font-size: 20px;
  font-weight: 700;
  // margin-bottom: 15px;
}

.hero__slide-desc {
  font-size: 14px;
  max-width: 90%;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0px !important;
  // top: 12px !important;
  bottom: 0;
  left: auto;
  // height: 100% !important;
  border-radius: 0;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0 !important;
  // top: 12px !important;
  // height: 100% !important;
  background-color: transparent;
}

.hero__arrow-prev:hover {
  background-color: #ffffff54;
}

.hero__arrow-prev {
  border-radius: 0;
}

.hero__arrow-next:hover {
  background-color: #ffffff50;
}

.hero__arrow-next {
  background-color: transparent;
}

.hero__arrow-prev:focus-visible {
  border-radius: 0;
  border: none;
  outline: transparent;
}

.hero__arrow-next:focus-visible {
  border-radius: 0;
  border: none;
  outline: transparent;
}

@media (max-width: 768px) {
  img.hero__slide-width-img {
    z-index: 5;
    display: none;
  }

  .hero__slide-content {
    max-width: 100%;
  }

  .hero__slide-container {
    padding: 10px 35px;
  }
}

@media (max-width: 650px) {
  img.hero__title-slider {
    font-size: 26px;
  }

  .hero__slide-container {
    min-height: 190px;
  }
}

@media (max-width: 420px) {
  .hero__title-slider {
    font-size: 18px;
  }

  .lineOne {
    margin-bottom: 10px;
  }
}

.first-window {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 30px;
  // background-color: #b8b8b8;
  background-image: url('https://images.unsplash.com/photo-1576613109753-27804de2cba8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVwYWlyJTIwcGhvbmV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &__data {

    display: grid;
    gap: 7rem;
    grid-template-columns: 1.3fr 0.8fr;
  }

  &__data form {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 15px 30px;
    border-radius: $radius;

    backdrop-filter: blur(5px);
    background-color: rgba(238, 238, 238, 0.716);
    box-shadow: 0 0 5px rgba(233, 233, 233, 0.904);
  }

  &__data form h3 {
    text-align: center;
    margin-bottom: 1rem;
  }

  &__content {
    color: $white;
    padding-top: 25px;
    padding-left: 10px;
    border-radius: $radius;
    backdrop-filter: blur(3px);
    background-color: #2a2a2a26;
    height: fit-content;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.157);
  }

  &__title {
    font-size: 3rem;
    font-weight: 800;
  }

  @media(max-width: 1024px) {
    &__title {
      font-size: 2rem;
    }

    &__data {
      gap: 2rem;
    }
  }

  @media(max-width: 924px) {
    &__data {
      grid-template-columns: 1fr;
    }

    &__content {
      text-align: center;
      max-width: 667px;
      margin: 0 auto;


    }

    &__data form {
      max-width: 414px;
      margin: 0 auto;
    }
  }

  @media(max-width: 768px) {
    &__content p {
      font-size: .8rem;
    }
  }

  @media(max-width: 490px) {
    &__title {
      font-size: 1.6rem;
    }
  }
}
