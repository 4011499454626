.offer-calc {
  padding: 20px 0;
  background-image: url(../img/pattern-offer-calc.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__data-wrap {
    width: 50%;
    padding: 20px 40px;
    background-color: rgba(252, 252, 252, 0.6);
    border-radius: $br-10;
    box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.25);
  }

  //  data

  &__title {
    text-align: center;
    color: $blue;
  }

  &__desc {
    text-align: center;
    font-size: 14px;
    padding: 5px 5%;
    font-weight: 200;
  }

  &__h3-wrap {
    border-radius: $br-10;
    padding: 10px 20px;
    text-align: center;
    background: linear-gradient(266.61deg, #ae15c7 -7.95%, #0a74c0 104.29%);
  }
  &__title-h3 {
    color: $white;
  }
  &__h3-desc {
    color: $white;
    font-weight: 200;
    font-size: 12px;
  }

  // form

  &__form {
    padding: 15px 0;
  }
  &__lable-form {
    display: block;
    margin-bottom: 0.4rem;
    color: $blue;
    padding-left: 1rem;
    font-weight: 300;
    font-size: 0.8rem;
  }
  &__select ~ .choices__list--single {
    color: $blue;
    font-size: 0.8rem;
  }
  &__select-container:not(:last-child) {
    margin-bottom: 18px;
  }

  &__select-wrapper > .choices > .choices__inner {
    border: 2px solid $blue;
    padding: 0;
    padding: 10px;
  }

  &__rules {
    font-size: 0.7rem;
    font-weight: 100;
    text-align: center;
  }
  &__price-wrap {
    padding: 10px 0;
    text-align: center;
    color: $blue;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: $firstFont, sans-serif;
  }
  &__price-wrap p {
    padding: 0;
    margin: 0;
  }

  &__price-wrap p:not(:last-child) {
    margin-bottom: 5px;
  }
  &__price-sell {
    font-size: 1.3rem;
    color: $red;
  }

  &__phone-input {
    border: 2px solid $blue;
    width: 100%;
    padding: 10px;
    border-radius: 50px;
  }
  &__btn {
    background: linear-gradient(266.61deg, #ae15c7 -7.95%, #0a74c0 104.29%);
    width: 100%;
    color: $white;
    font-family: $firstFont, sans-serif;
    font-size: 1.1rem;
    font-weight: 700;
    text-align: center;
    border-radius: 50px;
  }
  &__btn:hover {
    background: linear-gradient(266.61deg, #be2fd4 -7.95%, #280ac0 104.29%);
    color: $white;
  }
  &__btn:active {
    background: $black;
    color: $white;
  }

  @media (max-width: 860px) {
    &__data-wrap {
      width: 70%;
    }
  }
  @media (max-width: 600px) {
    &__data-wrap {
      width: 100%;
    }
    &__title {
      font-size: 1.4rem;
    }
    &__title-h3 {
      font-size: 1.1rem;
    }
  }
}
