.politics-info {
    margin-bottom: 30px;
    &__desc {
        padding: 0px 20px;
    }
}

.simplebar-wrapper {
    height: 40vh;
    border: 2px solid rgb(212, 212, 212);
    box-shadow: 0 4px 15px rgb(212, 212, 212);
    border-radius: $radius;
}

.simplebar-scrollbar::before {
    background-color: $blue;
}
