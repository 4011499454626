.border-green {
  border: 1px solid $green-light;
  border-radius: $btn-radius;
}

.с-green-light {
  color: $green-light;
}

.border-orange {
  border: 1px solid $orange;
  border-radius: $btn-radius;
}

.с-orange {
  color: $orange;
}

.product {
  margin-bottom: $sec-bottom;
  padding: 15px 0;
  &__special-offer {
    padding: 4px;
    font-size: 0.7rem;
    font-weight: 500;
    width: 65%;
  }
  &__list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin: 0 auto;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: $radius;
    padding: 20px 10px;
    transition: all 0.2s ease-in-out;
  }
  &__item p {
    margin: 0;
    margin-bottom: 15px;
  }
  &__char-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__char-item {
    font-weight: 500;
    font-size: 10px;
    color: $blue;
    text-transform: uppercase;
    padding: 2px 4px;
    border-radius: 4px;
  }
  &__avatar-container {
    display: flex;
    justify-content: center;
    height: 200px;
    margin-bottom: 20px;
  }
  &__img-avatar {
    object-fit: contain;
  }
  &__avatar {
    // width: 75%;
    margin: 0 auto;
  }
  &__rating {
    margin-bottom: 15px;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    padding-right: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  &__price-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__price-container {
    font-family: $firstFont serif;
    display: flex;
    flex-direction: column;
  }
  &__old-price {
    font-size: 13px;
    color: $gray;
    font-weight: 400;
    margin-bottom: 10px;
    text-decoration: line-through;
  }
  &__actual-price {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: $firstFont;
  }
  &__btn {
    border: 2px solid;
    font-weight: 600;
    padding: 1px 10px;
    height: 35px;
  }
  &__btn:hover {
    color: #ffffff;
    border: 2px solid $blue;
    background-color: $blue;
    font-weight: 600;
    padding: 1px 10px;
    height: 35px;
  }
  &__btn:active {
    border: 2px solid $black;
    background-color: $black;
    font-weight: 600;
    padding: 1px 10px;
    height: 35px;
  }
  &__btn-more-container {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 80px;
  }
  &__btn-more-container a {
    text-align: center;
  }
  &__btn-more {
    display: inline-block;
    width: 320px;
  }
  &__btn-more:hover {
    color: #fff;
    background-color: $blue;
  }
  &__btn-more:active {
    color: #fff;
    border: 1px solid $black;
    background-color: $black;
  }
}

.rating-star {
  background-image: url(../img/yellow-star.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: left;
}

// filter

.filter__list {
  display: flex;
  margin-bottom: 10px;
}
.filter__item {
  border: 1px solid $blue;
  padding: 3px;
  color: $blue;
  background: $white;
  border-radius: 4px;
  cursor: pointer;
}

.filter__item:not(:last-child) {
  margin-right: 6px;
}
.hide-product {
  // position: absolute;
  display: none;
}

@media (max-width: 980px) {
  .product__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 820px) {
  .product__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .product__btn {
    width: 140px;
  }
  .product__actual-price {
    font-size: 20px;
  }
}

@media (max-width: 650px) {
  .product__list {
    grid-template-columns: repeat(1, 1fr);
  }
  .product__btn-more {
    width: 100%;
  }
  .product__char-item {
    font-size: 14px;
  }
}
