    .gidrogel {
        margin-bottom: $sec-bottom;
        position: relative;
        &__benefit-info-img {
            object-fit: contain;
            width: 40vw;
        }
        &__desc {
            max-width: 75vw;
            margin-bottom: 30px;
        }
        &__benefit-info-title {
            text-align: center;
            margin-bottom: 30px;
        }
        &__benefit-info {
            display: flex;
            flex-direction: column;
        }
        &__benefit-info-wrapper {
            display: flex;
        }
        &__benefit-info-list {
            counter-reset: list;
        }
        &__benefit-info-item {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
        }
        &__benefit-info-item::before {
            counter-increment: list;
            content: counter(list) ". ";
        }
        &__benefit-info-span {
            display: block;
        }
        &__benefit-info-span:not(:last-child) {
            margin-bottom: 20px;
        }
        @media (max-width: 787px) {
            &__benefit-info-wrapper {
                display: flex;
                flex-direction: column-reverse;
            }
            &__benefit-info-img {
                width: auto;
            }
            &__desc {
                max-width: 100%;
            }
        }
    }