.fixed-call-btn__btn {
    position: fixed;
    bottom: 20px;
    margin: auto;
    left: calc(50vw - 104px);
    width: 200px;
    padding: 10px 0px;
    font-weight: 600;
    border-radius: $radius;
    z-index: 1000;
}
