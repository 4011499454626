.services {
    margin-bottom: $sec-bottom;
    &__list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
    }
    &__item {
        position: relative;
        min-height: 100px;
        border-radius: $radius;
        background: $light-blue;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        // box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.100);
        transition: background .3s ease-in-out;
    }
    &__item:hover {
        background: $light-blue-hover;
    }
    &__item-img {
        margin-bottom: 15px;
    }
    & a {
        display: block;
        color: black;
        text-align: center;
        font-size: .8rem;
        font-weight: 400;
        transition: color .2s ease-in;
    }
    & a::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        content: "";
    }
    @media (max-width: 1100px) {
        &__list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (max-width: 600px) {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (max-width: 375px) {
        &__list {
            grid-template-columns: 1fr;
        }
    }
}
