.disabled-btn{
  background: rgb(208, 208, 208);
  color: rgb(134, 134, 134);
  border: 1px solid transparent;
}

.disabled-btn:hover{
  background: rgb(208, 208, 208);
  color: rgb(134, 134, 134);
  border: 1px solid transparent;
}

.alert-mass {
  display: block;
  text-align: center;
  width: 100%;
  font-family: 'Jura', sans-serif;
  font-weight: 600;
  padding: 3px 10px;
  border-radius: 4px;
  margin-top: 1rem;
  display: inline-block;
}

.alert-mass--text {
  font-family:$firstFont, serif;
  font-size: .9rem;
  font-weight: 300;
  color: $black;
}

.bg-danger {
  background-color: rgb(249, 156, 156);
}
.bg-success {
  background-color: #ccffc9;
}
