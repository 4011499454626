.often-request {
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
    &__list {
        margin-top: 5px !important;
    }
    &__item {
        height: 5vh;
        width: auto !important;
    }
    &__link {
        font-size: 12px;
        padding: 3px 8px;
        border: 1px solid $often-blue;
        border-radius: 6px;
        color: #000;
        transition: background-color .2s ease-in-out;
    }
    &__link:hover {
        border: 1px solid $often-blue;
        background-color: $blue;
        color: #fff;
    }
}

.often-request__slider {
    // margin-bottom: 20px !important;
    min-height: 14px;
}

// arrow
.often-arrow-next {
    color: $blue;
    box-shadow: 0 0 10px $blue;
    top: 4px !important;
    height: 0 !important;
    border-radius: 100%;
    // background: #0a74c063;
    background: white;
    padding: 18px;
    transition: background .2s ease-in-out;
}

.often-arrow-prev {
    color: $blue;
    box-shadow: 0 0 10px $blue;
    top: 3px !important;
    height: 0 !important;
    border-radius: 100%;
    // background: #0a74c063;
    background: white;
    padding: 18px;
    transition: background .2s ease-in-out;
}

.often-arrow-next:active {
    color: $white;
    background: $blue-hover;
}

.often-arrow-prev:active {
    color: $white;
    background: $blue-hover;
}

.often-arrow-prev::after {
    font-size: 12px;
}

.often-arrow-next::after {
    font-size: 12px;
}

.often-arrow-prev.swiper-button-disabled {
    opacity: 0;
}

.often-arrow-next.swiper-button-disabled {
    opacity: 0;
}

@media (max-width:992px) {
    .often-request {
        &__link {
            color: #000;
        }
        &__link:hover {
            background-color: #fff;
            color: #000;
        }
        &__link:active {
            background-color: #fff;
            color: $blue;
        }
    }
}
