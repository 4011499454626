.phone-info-hero {
    margin-bottom: $sec-bottom;
}

.phone-info__hero-info-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    min-height: 50vh;
}

.phone-info__hero--background {
    width: 100%;
    background-image: url(../img/iphone-11-1-1.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
}

.phone-info__hero-container h1 {
    margin-bottom: 40px;
}

.phone-info__variant-list {
    position: relative;
    font-size: 20px;
    font-weight: 600;
}

.phone-info__variant-item {
    margin-left: 18px;
}

.phone-info__variant-item:not(:last-child) {
    margin-bottom: 15px;
}

.phone-info__variant-item::before {
    content: '';
    position: absolute;
    left: 0;
    margin-top: 7px;
    width: 8px;
    height: 8px;
    background-color: $dark;
}

@media (max-width: 992px) {
    .phone-info__hero-container h1 {
        font-size: 22px;
    }
    .phone-info__variant-list {
        font-weight: 500;
        font-size: 16px;
    }
    .phone-info__variant-item::before {
        margin-top: 3px;
    }
}

@media (max-width: 860px) {
    .phone-info__hero-container h1 {
        font-size: 18px;
    }
}

@media (max-width: 670px) {
    .phone-info__hero--background {
        background-image: none !important;
    }
    .phone-info__hero-container h1 {
        font-size: 20px;
    }
    .phone-info__variant-list {
        max-width: 100vw;
    }
    .phone-info__hero-info-container {
        min-height: auto;
        padding: 45px 0;
    }
}
