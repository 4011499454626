.orders {
    &__block-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5vh 0;
    }
    &__image-block {
        display: flex;
        justify-content: center;
        width: 50%;
    }
    &_form-block {
        width: 50%;
    }
    &__image {
        width: 50%;
    }
    @media (max-width: 920px) {
        &__block-wrapper {
            flex-direction: column;
        }
        &__block-wrapper {
            height: auto;
        }
    }
}

.order-status {
    display: inline-block;
    color: $blue;
    padding: 4px 0;
    margin-top: 5px;
    text-align: center;
    width: 100%;
    font-size: .8rem;
}

.d-flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

// livesklad style
.livesklad-link {
    display: none;
}
