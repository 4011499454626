.review {
    margin-bottom: $sec-bottom;
    padding: 15px 0;
    // background: greenyellow;
    &__slider-container {
        display: flex;
        justify-content: center;
    }
    &__list-wrapper {
        position: relative;
    }
    &__slide {
        min-height: 50vh;
        padding: 15px 0;
    }
    &__slide-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__slide-desc {
        text-align: center;
        max-width: 760px;
        margin-bottom: 25px;
    }
    &__client-name {
        font-weight: 600;
        font-size: 18px;
        padding-bottom: 50px;
    }
    &__yandex {
        z-index: 10;
        position: absolute;
        bottom: 15%;
        // right: 46%;
    }
    // arrows
    &__arrow-next {
        color: $gray;
        background-color: rgba(255, 255, 255, 0.459);
        padding: 25px;
        border: 1px solid $gray;
        border-radius: 50%;
        transition: color .2s ease-in-out, border .2s ease-in-out;
    }
    &__arrow-prev {
        color: $gray;
        background-color: rgba(255, 255, 255, 0.459);
        padding: 25px;
        border: 1px solid $gray;
        border-radius: 50%;
        transition: color .2s ease-in-out, border .2s ease-in-out;
    }
    &__arrow-next:hover {
        color: $blue;
        border: 1px solid $blue;
        background-color: #ffffffbe;
    }
    &__arrow-prev:hover {
        color: $blue;
        border: 1px solid $blue;
        background-color: #ffffffbe;
    }
    &__arrow-next::after {
        font-size: 24px;
    }
    &__arrow-prev::after {
        font-size: 24px;
    }
    &__yandex-container {
        display: flex;
        justify-content: center;
    }
}

.reviews-slider {
    min-height: 50vh
}
