.footer {
  // margin-bottom: $sec-bottom;
  padding-top: 40px;
  padding-bottom: 20px;
  background-color: $dark-blue;
  //
  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }
  &_img-logo {
    max-width: 160px;
  }
  &__nav-title {
    margin-bottom: 18px;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
  }
  &__nav-list {
    display: flex;
    flex-direction: column;
  }
  &__nav-item:not(:last-child) {
    margin-bottom: 8px;
  }
  &__nav-link {
    font-size: 12px;
    font-weight: 400;
    color: $silver;
    transition: color 0.2s ease-in-out;
  }
  &__nav-link:hover {
    color: $gray;
  }
  &__connect-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__number-wrapper {
    margin-bottom: 35px;
  }
  &__number-link {
    display: block;
    font-family: $firstFont;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  // social
  &__social-list {
    display: flex;
    margin-bottom: 35px;
  }
  // &__social-link:hover {}
  // &__social-item {
  //     width: 45px;
  //     height: 45px;
  // }
  &__social-item:not(:last-child) {
    margin-right: 20px;
  }
  // down footer
  &__down-container {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
  }
  &__down-content-wrapper {
    color: $gray;
    font-size: 12px;
    font-weight: 400;
    transition: color 0.2s ease-in-out;
  }
  &__down-ui-code-link {
    border-bottom: 1px solid $gray;
    transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;
  }
  &__down-ui-code-link:hover {
    color: $silver;
    border-bottom: 1px solid $silver;
  }
}

.footer-title-ya-rating {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: end;
}

.rating-yandex {
  display: flex;
  justify-content: flex-end;
}

// alerts
.close-point-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(97, 97, 97, 0.456);
  display: none;
  justify-content: center;
}
.c-point-container__active {
  display: flex !important;
  overflow-y: hidden;
}
.close-point__info-container {
  width: 80%;
  padding: 50px 0 20px 0;
  position: relative;
}
.close-point__btn-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: red;
  color: white;
  border: none;
}

.close-point__info-block {
  position: relative;
  padding: 40px 20px 70px 20px;
  background-color: rgb(253, 253, 253);
  box-shadow: 0 0 15px 5px rgba(31, 31, 31, 0.331);
}

@media (max-width: 820px) {
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    justify-content: space-between;
  }
  .footer__connect-wrapper {
    align-items: flex-start;
  }
  .footer__logo-link {
    order: 3;
  }
  .footer-title-ya-rating {
    text-align: start;
  }
  .rating-yandex {
    justify-content: flex-start;
  }
}

@media (max-width: 500px) {
  .footer__container {
    grid-template-columns: repeat(1, 1fr);
  }
  .footer__logo-link {
    order: 0;
  }
}
