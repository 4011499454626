.header {
  background-color: #ffffff;

  // margin-bottom: 30px;
  &__tooltip-link {
    padding: 3px 10px;
  }

  &__top-right-content--title {
    font-family: $firstFont;
    position: relative;
    padding-right: 10px;
    font-size: .9rem;
    padding-left: 16px;
  }

  &__top-right-content--title::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: red;
    left: 0;
    top: 3px;
    border-radius: 100%;
    animation: ligthouse 1s infinite alternate;
  }

  @keyframes ligthouse {
    0% {
      box-shadow: 0 0 0 red;
    }

    100% {
      box-shadow: 0 0 8px red;
    }
  }
}

.header__top {
  background-color: #f1f1f1;

  &-right-content {
    display: flex;
    align-items: center;
  }

  &-tooltip-wrap {
    margin-right: 30px;
    position: relative;
  }

  &-tooltip-info-btn {
    background: white;
    color: red;
    padding: 3px;
    font-size: 12px;
    font-weight: 600;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    border: 1px solid red;
    transition: color .3s ease-in-out, background .3s ease-in-out;
  }

  &-tooltip-info-btn--active {
    background: rgb(255, 0, 0);
    color: white;
  }

  &-tooltip-info-container {
    visibility: hidden;
    position: absolute;
    margin-top: 18px;
    background: #d9eefc;
    box-shadow: 2px 2px 12px rgb(218, 218, 218);
    border-radius: $btn-radius;
    color: rgb(0, 0, 0);
    min-width: 300px;
    padding: 10px 25px;
    right: 0;
    z-index: 500;
  }

  &-tooltip-info-container--active {
    visibility: visible !important;
  }

  &-tooltip-info-title {
    font-size: 16px;
    font-weight: 600;
  }

  &-tooltip-info-desc {
    font-size: 12px;
    padding-right: 5px;
  }

  @keyframes headertooltiparr {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(15px);
    }
  }

  &-tooltip-arrow {
    animation: headertooltiparr 1s alternate ease-in-out infinite;
    position: absolute;
    right: -50px;
    top: 50px;
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 8px rgb(92, 92, 92);
    border-radius: 100%;
    background: url(../img/tooltip-arrow.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.header__top-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.header__top-nav-list {
  display: flex;
}

.header__top-nav-item:not(:last-child) {
  padding-right: 10px;
  border-right: 1px solid #e4e4e4;
}

.header__top-nav-item:not(:first-child) {
  padding-left: 10px;
}

.header__top-nav-link {
  font-size: 12px;
  border-bottom: 1px solid transparent;
  transition: color .2s ease-in-out, border-bottom .2s ease-in-out;
}

.header__top-nav-link:hover {
  color: $blue;
  // border-bottom: 1px solid $blue;
}

.header__top-nav-link:active {
  color: $light-blue;
}

.header__top-mail-title {
  color: $gray;
  font-size: 14px;
}

.header__top-mail-text {
  font-size: 14px;
  // border-bottom: 1px solid transparent;
  transition: color .2s ease-in-out;
}

.header__top-mail-text:hover {
  color: $blue;
  border-bottom: 1px solid $blue;
}

.header__top-mail-text:active {
  color: $light-blue;
}

.header__top-city-button {
  font-size: 14px;
  background-color: transparent;
  border: 1px solid $blue;
  border-radius: 5px;
  padding: 4px 15px;
}

// middle
.header__middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.header__middle-logo {
  width: 200px;
}

.header__middle-list {
  display: flex;
}

.header__middle-item:not(:last-child) {
  margin-right: 25px;
}

.header__middle-link {
  color: $dark;
  font-family: $firstFont;
  font-size: .9rem;
  padding-bottom: 3px;
  transition: color .2s ease-in-out;
}

.header__middle-link:hover {
  color: $blue;
  // border-bottom: 1px solid $blue;
}

.header__middle-callback-block {
  display: flex;
  flex-direction: column;
}

.header__middle-callback-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header__middle-number-phone {
  font-family: $firstFont;
  color: $black;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

// bottom
.header__bottom {
  display: flex;
  justify-content: center;
  background: $blue;
  color: #fff;
  font-size: 12px;
  margin-bottom: 20px;
}

.header__bottom-desc a {
  color: #fff;
  border-bottom: 1px solid transparent;
  transition: border-bottom .2s ease-in-out;
  padding-top: 4px;
  padding-bottom: 4px;
}

.header__bottom-desc a:hover {
  border-bottom: 1px solid #fff;
}

.arrow-right {
  padding-right: 12px;
  background-image: url(/img/arrow.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 8px;
}

.burger-toggle-btn {
  // visibility: visible;
  display: none;
}

// --------------------------
.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 20px;
  background: #dfd9ea;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
}

.switch::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 2px;
  width: 22px;
  height: 22px;
  background: #fafafa;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

input:checked+.switch {
  background: #72da6791;
}

input:checked+.switch::before {
  left: 27px;
  background: #fff;
}

input:checked+.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

.header-top__switch {
  position: relative;
}

.header-switch-triangle {
  display: none;
  position: absolute;
  top: 8px;
  left: 6px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom: 20px solid #cce3f3;
}

.header-switch-triangle--active {
  display: block;
}

// ------------------------
@media(max-width:992px) {
  .header__middle-number-phone {
    font-size: 14px;
    font-weight: 600;
  }

  .btn {
    font-size: 14px;
  }

  .header__middle-link {
    color: $dark;
    padding-bottom: 3px;
    font-size: .8rem;
  }

  .header__middle-item:not(:last-child) {
    margin-right: 12px;
  }

  .header__middle-logo {
    width: 180px;
  }
}

@media (max-width: 860px) {
  .header__middle-list {
    transform: translateX(-100%);
    transition: transform .7s ease;
  }

  .header__middle-list {
    position: absolute;
    left: 0;
    top: 0;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 99;
    padding: 50px 50px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(102, 102, 102, 0.357);
    min-height: 80vh;
    min-width: 50vw;
  }

  .header__middle-item {
    margin-bottom: 30px;
  }

  .header__middle-link {
    font-weight: 600;
    // padding: 10px;
    // border-radius: $btn-radius;
    // background-color: $light-blue;
    // border: none;
    outline: none;
  }

  .header__middle-link:active {
    border: none;
    outline: none;
  }

  .nav-open {
    transform: translateX(0) !important;
  }

  .burger-toggle-btn {
    // visibility: hidden;
    display: none;
    padding: 12px;
    border: none;
    border-radius: 100%;
    background-color: $blue;
    transition: all .2s cubic-bezier(.42, 0, .08, 1.44);
  }

  .burger-toggle-btn {
    // visibility: visible;
    display: block !important;
  }

  // .burger-active {
  //     border-radius: 100%;
  // }
  .burger-toggle-icon {
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.64341 6.99901L13.6552 1.99813C13.8747 1.77862 13.998 1.48091 13.998 1.17048C13.998 0.860046 13.8747 0.562331 13.6552 0.342824C13.4358 0.123318 13.1381 0 12.8277 0C12.5173 0 12.2196 0.123318 12.0002 0.342824L7 5.35536L1.99983 0.342824C1.78036 0.123318 1.48268 -2.31288e-09 1.1723 0C0.861913 2.31288e-09 0.56424 0.123318 0.344765 0.342824C0.125289 0.562331 0.00198911 0.860046 0.00198911 1.17048C0.00198911 1.48091 0.125289 1.77862 0.344765 1.99813L5.35659 6.99901L0.344765 11.9999C0.235521 12.1083 0.148811 12.2372 0.0896384 12.3792C0.0304655 12.5213 0 12.6736 0 12.8275C0 12.9814 0.0304655 13.1338 0.0896384 13.2758C0.148811 13.4179 0.235521 13.5468 0.344765 13.6552C0.453117 13.7644 0.582027 13.8512 0.724059 13.9103C0.866091 13.9695 1.01843 14 1.1723 14C1.32616 14 1.47851 13.9695 1.62054 13.9103C1.76257 13.8512 1.89148 13.7644 1.99983 13.6552L7 8.64265L12.0002 13.6552C12.1085 13.7644 12.2374 13.8512 12.3795 13.9103C12.5215 13.9695 12.6738 14 12.8277 14C12.9816 14 13.1339 13.9695 13.2759 13.9103C13.418 13.8512 13.5469 13.7644 13.6552 13.6552C13.7645 13.5468 13.8512 13.4179 13.9104 13.2758C13.9695 13.1338 14 12.9814 14 12.8275C14 12.6736 13.9695 12.5213 13.9104 12.3792C13.8512 12.2372 13.7645 12.1083 13.6552 11.9999L8.64341 6.99901Z' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    transition: all .4s cubic-bezier(.42, 0, .08, 1.44);
  }

  .burger-toggle-icon-active {
    transform: rotate(0);
  }

  .header__bottom {
    display: none;
  }

  .header__middle-callback-block {
    display: none;
  }

  .header__top-flex-container div {
    display: none;
  }
}

// @media (max-width: 768px) {
// }
