.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    /*фиксированное позиционирование*/
    position: fixed;
    /* координаты положения */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    /* фоновый цвет элемента */
    background: #ffffff;
    /* размещаем блок над всеми элементами на странице (это значение должно быть больше, чем у любого другого позиционированного элемента на странице) */
    z-index: 1001;
    background-image: url('/img/preload.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 5.8vw;
}

.preloader__row {
    width: 45vw;
    height: 45vh;
    background-image: url(/img/preload-circle.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10vw;
    animation: preloader-rotate 7s infinite linear;
}

@keyframes preloader-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loaded_hiding .preloader {
    transition: 0.3s opacity;
    opacity: 0;
}

.loaded .preloader {
    display: none;
}

@media (max-width: 870px) {
    .preloader {
        background-size: 21vw;
    }
    .preloader__row {
        width: 90vw;
        height: 90vh;
        background-size: 35vw;
    }
}
