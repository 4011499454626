.section-banner {
    background: linear-gradient(45deg, $section-banner, $white);
    border-radius: $radius;
    padding: 0 25px 0 25px;
    min-height: 50vh;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    &__h1 {
        font-weight: 600;
        max-width: 30vw;
        margin-bottom: 40px;
        z-index: 10;
    }
    &__desc {
        font-size: 14px;
        font-weight: 400;
        max-width: 30vw;
        z-index: 10;
    }
    &__img {
        display: block;
        width: 380px;
        height: 300px;
        object-fit: contain;
        z-index: 5;
    }
    @media (max-width:992px) {
        &__img {
            width: 280px;
        }
        &__h1 {
            font-size: 28px;
            max-width: 80vw;
        }
    }
    @media (max-width:798px) {
        &__img {
            position: absolute;
            top: 25px;
            right: 0;
            width: 240px;
        }
        &__desc {
            max-width: 50vw;
        }
    }
    @media (max-width:650px) {
        &__img {
            display: none;
        }
        &__desc {
            font-size: 14px;
            font-weight: 400;
            max-width: 90vw;
        }
    }
}
