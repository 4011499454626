.trust {
    margin-bottom: $sec-bottom;
    &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
    }
    &__item {
        background-color: #ffffff;
        max-width: 100%;
        padding: 0 5px 0 5px;
        padding-top: 95px;
        text-align: center;
        box-shadow: 0px 0px 10px #efefefa3;
        border-radius: 6px;
    }
    &__item h3 {
        font-size: 1.3rem;
        font-weight: 800;
    }
    &__item p {
      font-size: .85rem;
  }
}

.trust__qval-bg {
    background-image: url(../img/qval.svg);
    background-repeat: no-repeat;
    background-position: center 15px;
    background-size: 60px;
}

.trust__term-bg {
    background-image: url(../img/term.svg);
    background-repeat: no-repeat;
    background-position: center 15px;
    background-size: 60px;
}

.trust__proffesional-bg {
    background-image: url(../img/professional.svg);
    background-repeat: no-repeat;
    background-position: center 15px;
    background-size: 60px;
}

.trust__possibilities-bg {
    background-image: url(../img/possibilities.svg);
    background-repeat: no-repeat;
    background-position: center 15px;
    background-size: 60px;
}

@media (max-width: 860px) {
    .trust__list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 650px) {
    .trust__list {
        grid-template-columns: repeat(1, 1fr);
    }
}
