.acc-map {
    padding: 15px 0;
    margin-bottom: $sec-bottom;
    &__wrapper {
        display: flex;
        flex-direction: column;
    }
    &__map-container {
        margin-top: 30px;
        border-radius: $radius;
        // max-width: 550px;
    }
    &-red {
        background: red;
    }
    &-blue {
        background: rgb(18, 228, 228);
    }
}

// swiper
.swiper-accord-slider {
    width: 600px;
    height: 300px;
}


/* acc */

.catalog-tabs__profile-acc {
    width: 50%;
}

.acc-inner {
    margin-top: 0px;
}

.acc-item__button {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    border-top: 1px solid $dark;
    border-bottom: 1px solid $dark;
    font-size: 20px;
    text-align: left;
    font-weight: 700;
    min-height: 70px;
    margin: 0;
}

.acc-item__button:hover>.acc-item__title {
    color: $blue;
}

.acc-item__title {
    color: $dark;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
    margin-right: 30px;
    transition: .2s ease-in-out;
}

.ui-accordion-header-active>.acc-item__title {
    color: $blue;
}

.acc-item__text {
    margin: 0;
    font-size: 14px;
    padding: 1em 2.2em;
    font-weight: 600;
    border-top: 0;
    overflow: auto;
    border-bottom: 1px solid $gray;
}

.acc-item__accord {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid $blue;
    background-color: transparent;
    background-image: url("/img/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    transform: rotate(45deg);
    transition: transform .2s cubic-bezier(.42, 0, .08, 1.44), background-color .3s cubic-bezier(.42, 0, .08, 1.44), border .3s cubic-bezier(.42, 0, .08, 1.44), border-top .3s cubic-bezier(.42, 0, .08, 1.44), border-bottom .3s cubic-bezier(.42, 0, .08, 1.44), color .3s cubic-bezier(.42, 0, .08, 1.44);
}

.acc-item__button:hover>.acc-item__accord {
    background-color: transparent;
}

.acc-item__accord-active {
    background-image: url("/img/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid $blue;
    transform: rotate(90deg);
}


/* переопределяем стили которые задаёт jqueryUI */

.ui-accordion .ui-accordion-header {
    margin-top: -1px;
}

.ui-state-active {
    border-top: 1px solid $blue;
    border-bottom: 1px solid $blue;
}

.acc-item__text-desc {
    display: block;
    max-width: 320px;
    margin-bottom: 5px;
}

.mt20 {
    margin-top: 20px;
}

// map
.map-width {
    min-height: 50vh;
    width: 100%;
}

[class*="ground-pane"] {
    filter: grayscale(50%);
}

[class*="gotoymaps"],
[class*="gototech"],
[class*="copyrights"] {
    display: none;
}

@media (max-width: 1020px) {
    .acc-map__wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
    .map-width {
        min-width: 100%;
    }
}

@media (max-width: 620px) {
    .acc-map__map-container {
        max-width: 100vw;
    }
}
