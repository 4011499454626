.cost-now{

margin-bottom: 20px;

  &__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 160px 50px;
    background-image: url('../img/cost-now-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__title{
    color: $white;
  }

  &__data{
    max-width: 50%;
  }
  &__imag-wrap{
    width: 30%;
  }
  &__imag-wrap img{
    width: 100%;
  }
  // form

  &__form{
    width: 70%;
  }

  &__lable-form{
    padding-left: 1rem;
    margin-bottom: 0.4rem;
    color: $white;
    font-size: .8rem;
    font-weight: 300;
  }
  &__btn{
    border: none;
    border-radius: 50px;
    background-color: $cost-now-bg;
    height: 42px;
    color: $white;
    font-weight: 700;
    font-size: 1.2rem;
    box-shadow: 0 4px 10px 5px #2a2a2a29;
    transition: background-color .1s ease-in-out;
  }

  &__btn:hover{
    border: none;
    border-radius: 50px;
    background-color: $cost-now-bg-light;
    height: 42px;
    color: $white;
    font-weight: 700;
    font-size: 1.2rem;
  }

  @media(max-width: 1024px){
    &__data{
      max-width: 70%;
    }
    &__form{
      width: 80%;
    }
    &__imag-wrap{
      width: 30%;
    }
  }

  @media(max-width: 820px){
    &__data{
      max-width: 80%;
    }

    &__imag-wrap{
      display: none;
    }
  }


  @media(max-width: 650px){
    &__data{
      max-width: 100%;
    }
    &__form{
      width: 100%;
    }
    &__container{
      padding-top: 130px;
      padding-bottom: 35px;
      padding-left: 10px;
      padding-right: 10px;
    }

  }
}
